import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'
import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { MdSportsSoccer } from "react-icons/md";
import { SiPremierleague } from "react-icons/si";
import { LiaGlobeEuropeSolid } from "react-icons/lia";


function Home() {

  const [authUser, setAuthUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate()
    const handleClick = () => {
        alert('This should be a cool alert')
    }
    const notify = () => toast("Wow so easy!");

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setAuthUser(user);
        } else {
          setAuthUser(null);
        }
      });
  
      return () => unsubscribe();
    }, []);
  
    const userSignOut = () => {
      signOut(auth).then(() => {
        console.log('signed out');
        navigate('/login');
      }).catch(error => console.log(error));
    };
  

    return (
        <div>
            <Navbar />
            <div className='App-headerV8'>

            <section className="bg-transparent py-3 py-md-5 py-xl-8">
  <div className="container">
    <div className="row justify-content-md-center">
      <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 className="mb-4 display-5 text-center">Predictify</h2>
        <p className="text-secondary mb-2 text-center lead fs-4">Welcome to Predictify's Sports Predictor Game!</p>
        <div>
      {authUser ? (
        <div >
          {authUser.emailVerified ? (
            <>
        
   <div >

    </div>         </>
          ) : (
            <div >
            
            </div>
          )}
        </div>
      ) : 
      <div >
  <p className="text-secondary mb-5 text-center lead fs-4 d-flex justify-content-center">
  <span className="text-secondary mb-2 lead fs-4 homehover" style={{marginRight: '5px'}}>
    <Link className='homehover' to='/signup' style={{textDecoration: 'none', color: 'green'}}> Sign Up </Link>
  </span>
  now to join in the fun!
</p>

    </div>  }
    </div>


        <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>
{/** sign up btn */}

{/** sign up btn */}


  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row gy-3 gy-md-4">
          {/* <div className="col-12 col-md-6 col-lg-6">
              <div className="card border-dark">
                <div className="card-body text-center p-4 p-xxl-5">
        
                                                    <LiaGlobeEuropeSolid  size={58} style={{color: 'purple'}} className='mb-4' />

                  <h4 className="mb-4">Euro 2024</h4>
                  <p className="mb-4 text-secondary">{t('euro2024')}</p>
                  <Link to='/joinhuddle' className='text-light' state={{ tournament: 'EURO 2024' }} style={{color: 'white'}}><span className="btn bg-danger text-light ">{t('playnow')}</span> </Link> 

                  <br />
                  <Link to='/eurolearnmore'>  <span className="btn  text-dark">{t('learnmore')}</span> </Link>  

           
                </div>
              </div>
            </div> */}
            <div className="col-12 col-md-6 col-lg-6">
              <div className="card border-dark">
                <div className="card-body text-center p-4 p-xxl-5">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-exclude text-purple mb-4" viewBox="0 0 16 16">
                                         <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm12 2H5a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1z"/>
                                                   </svg> */}
                                                                                                       <MdSportsSoccer  size={58} style={{color: 'purple'}} className='mb-4' />

                  <h4 className="mb-4">English Premier League</h4>
                  <p className="mb-4 text-secondary">{t('epl')}</p>
                  <Link to='/joinhuddle' state={{ tournament: 'English Premier League' }}><span className="btn bg-danger text-light ">{t('playnow')}</span> </Link> 

<br />
              <Link to='/epllearnmore' >  <span className="btn  text-dark">{t('learnmore')}</span> </Link>  

                  
                </div>
                
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="card border-dark">
                <div className="card-body text-center p-4 p-xxl-5">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-exclude text-purple mb-4" viewBox="0 0 16 16">
                                         <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm12 2H5a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1z"/>
                                                   </svg> */}
                                                    <MdSportsSoccer size={58} style={{color: 'purple'}} className='mb-4' />

                  <h4 className="mb-4">Scottish Premiership</h4>
                  <p className="mb-4 text-secondary">The top 12 football clubs in Scotland make up the illustrious Scottish Premiership. Will Celtic defend their crown in the 24/25 season?</p>
                  <Link to='/joinhuddle' state={{ tournament: 'Scottish Premiership' }}><span className="btn bg-danger text-light ">{t('playnow')}</span> </Link> 

<br />
<Link to='/spfllearnmore' >  <span className="btn  text-dark">{t('learnmore')}</span> </Link>  

                  
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
  <br />
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row gy-3 gy-md-4">
          {/* <div className="col-12 col-md-6 col-lg-6">
              <div className="card border-dark">
                <div className="card-body text-center p-4 p-xxl-5">
        
                                                    <LiaGlobeEuropeSolid  size={58} style={{color: 'purple'}} className='mb-4' />

                  <h4 className="mb-4">Euro 2024</h4>
                  <p className="mb-4 text-secondary">{t('euro2024')}</p>
                  <Link to='/joinhuddle' className='text-light' state={{ tournament: 'EURO 2024' }} style={{color: 'white'}}><span className="btn bg-danger text-light ">{t('playnow')}</span> </Link> 

                  <br />
                  <Link to='/eurolearnmore'>  <span className="btn  text-dark">{t('learnmore')}</span> </Link>  

           
                </div>
              </div>
            </div> */}
            <div className="col-12 col-md-6 col-lg-6">
              <div className="card border-dark">
                <div className="card-body text-center p-4 p-xxl-5">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-exclude text-purple mb-4" viewBox="0 0 16 16">
                                         <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm12 2H5a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1z"/>
                                                   </svg> */}
                                                                                                       <MdSportsSoccer  size={58} style={{color: 'purple'}} className='mb-4' />

                  <h4 className="mb-4">English Championship</h4>
                  <p className="mb-4 text-secondary">With the English Championship's fierce competition, will Burnley hold their ground and secure promotion in the 24/25 season, or will a new contender emerge?</p>
                  <Link to='/joinhuddle' state={{ tournament: 'Championship' }}><span className="btn bg-danger text-light ">{t('playnow')}</span> </Link> 

<br />
              <Link to='/championshiplearnmore' >  <span className="btn  text-dark">{t('learnmore')}</span> </Link>  

                  
                </div>
                
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="card border-dark">
                <div className="card-body text-center p-4 p-xxl-5">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-exclude text-purple mb-4" viewBox="0 0 16 16">
                                         <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm12 2H5a1 1 0 0 0-1 1v7h7a1 1 0 0 0 1-1z"/>
                                                   </svg> */}
                                                                                                       <MdSportsSoccer  size={58} style={{color: 'purple'}} className='mb-4' />

                  <h4 className="mb-4">La Liga</h4>
                  <p className="mb-4 text-secondary">With La Liga's fierce competition, will Atlético Madrid hold their ground and secure Champions League qualification in the 24/25 season, or will a new contender emerge to disrupt the usual top-four race?</p>
                  <Link to='/joinhuddle' state={{ tournament: 'La Liga' }}><span className="btn bg-danger text-light ">{t('playnow')}</span> </Link> 

<br />
              <Link to='/laligalearnmore' >  <span className="btn  text-dark">{t('learnmore')}</span> </Link>  

                  
                </div>
                
              </div>
            </div>
    
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
  
</section>

            </div>
            <Footer />
        </div>
    )
}

export default Home;
