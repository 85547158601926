import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useTranslation } from 'react-i18next';

import Aberdeen from '../Team-Kits-Images/Aberdeen.png'
import Celtic from '../Team-Kits-Images/Celtic.png'
import Dundee from '../Team-Kits-Images/Dundee.png'
import DundeeUtd from '../Team-Kits-Images/DundeeUtd.png'
import Hearts from '../Team-Kits-Images/Hearts.png'
import Hibernian from '../Team-Kits-Images/Hibernian.png'
import Kilmarnock from '../Team-Kits-Images/Kilmarnock.png'
import Motherwell from '../Team-Kits-Images/Motherwell.png'
import Rangers from '../Team-Kits-Images/Rangers.png'
import RossCounty from '../Team-Kits-Images/RossCounty.png'
import StJohnstone from '../Team-Kits-Images/StJohnstone.png'
import StMirren from '../Team-Kits-Images/StMirren.png'


function ScottishFixturesBracket() {
    const [fixtures, setFixtures] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredFixtures, setFilteredFixtures] = useState([]);
    const [buttonLabel, setButtonLabel] = useState('Round');
    const { t } = useTranslation();

    const [dateRanges] = useState([
        { start: '2024-07-03', end: '2024-08-06' },  // Matchweek 1
        { start: '2024-08-06', end: '2024-08-12' },  // Matchweek 2
        { start: '2024-08-12', end: '2024-08-26' },  // Matchweek 3
        { start: '2024-08-26', end: '2024-09-02' },  // Matchweek 4 // 
        { start: '2024-09-02', end: '2024-09-16' },  // Matchweek 5
        { start: '2024-09-16', end: '2024-09-22' },  // Matchweek 6
        { start: '2024-09-22', end: '2024-09-29' },  // Matchweek 7
        { start: '2024-09-29', end: '2024-10-06' },  // Matchweek 8
        { start: '2024-10-06', end: '2024-10-20' },  // Matchweek 9
        { start: '2024-10-20', end: '2024-10-27' },  // Matchweek 10
        { start: '2024-10-27', end: '2024-10-31' },  // Matchweek 11
        { start: '2024-10-31', end: '2024-11-03' },  // Matchweek 12 // 
        { start: '2024-11-03', end: '2024-11-10' },  // Matchweek 13
        { start: '2024-11-10', end: '2024-11-24' },  // Matchweek 14
        { start: '2024-11-24', end: '2024-12-01' },  // Matchweek 15
        { start: '2024-12-01', end: '2024-12-05' },  // Matchweek 16
        { start: '2024-12-05', end: '2024-12-08' },  // Matchweek 17
        { start: '2024-12-08', end: '2024-12-15' },  // Matchweek 18
        { start: '2024-12-15', end: '2024-12-22' },  // Matchweek 19
        { start: '2024-12-22', end: '2024-12-27' },  // Matchweek 20
        { start: '2024-12-27', end: '2025-12-30' },  // Matchweek 21
        { start: '2025-12-31', end: '2025-01-03' },  // Matchweek 22
        { start: '2025-01-03', end: '2025-01-06' },  // Matchweek 23
        { start: '2025-01-06', end: '2025-01-12' },  // Matchweek 24
        { start: '2025-01-12', end: '2025-01-26' },  // Matchweek 25
        { start: '2025-01-26', end: '2025-02-02' },  // Matchweek 26
        { start: '2025-02-02', end: '2025-02-16' },  // Matchweek 27
        { start: '2025-02-16', end: '2025-02-23' },  // Matchweek 28
        { start: '2025-02-23', end: '2025-02-27' },  // Matchweek 29
        { start: '2025-02-27', end: '2025-03-02' },  // Matchweek 30
        { start: '2025-03-02', end: '2025-03-16' },  // Matchweek 31
        { start: '2025-03-16', end: '2025-04-30' },  // Matchweek 32
        { start: '2025-04-30', end: '2025-04-13' },  // Matchweek 33
        // Add more matchweeks as needed
    ]);
    
    const fetchFixtures = () => {
        fetch('https://iga-sport.azurewebsites.net/api/league/179/fixtures/season/2024', {
            headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
            }
        })
            .then(response => response.json())
            .then(data => {
                setFixtures(data);
            })
            .catch(error => {
                console.error('Error fetching fixtures:', error);
            });
    };

    useEffect(() => {
        fetchFixtures();
    }, []); // Empty dependency array to fetch data only once

    useEffect(() => {
        if (fixtures.length > 0 && dateRanges[currentPage - 1]) {
            const startDate = new Date(dateRanges[currentPage - 1].start);
            const endDate = new Date(dateRanges[currentPage - 1].end);
            console.log(`Filtering fixtures for matchweek ${currentPage}: ${startDate} - ${endDate}`);
            
            const filteredFixtures = fixtures.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                console.log(`Checking fixture date: ${fixtureDate}`);
                return fixtureDate >= startDate && fixtureDate <= endDate;
            });
    
            console.log(`Filtered Fixtures for matchweek ${currentPage}:`, filteredFixtures);
    
            const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
            setFilteredFixtures(sortedFixtures);
        }
    }, [currentPage, fixtures]); // Run when currentPage or fixtures change
    
    
    
    

    useEffect(() => {
        if (fixtures.length > 0 && dateRanges[currentPage - 1]) {
            const startDate = new Date(dateRanges[currentPage - 1].start);
            const endDate = new Date(dateRanges[currentPage - 1].end);
            const filteredFixtures = fixtures.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= startDate && fixtureDate <= endDate;
            });

            const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
            setFilteredFixtures(sortedFixtures);
        }
    }, [currentPage, fixtures]); // Run when currentPage or fixtures change

    const handlePageChange = (page) => {
        console.log('Changing to page:', page); // Debugging log
        setCurrentPage(page);
        setButtonLabel(`Round ${page}`);
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };

    const teamInitials = {
        'Celtic': 'CEL',
        'Heart Of Midlothian': 'HEA',
        'Motherwell': 'MOT',
        'Dundee Utd': 'DUU',
        'ST Mirren': 'STM',
        'Kilmarnock': 'KIL',
        'Hibernian': 'HIB',
        'Dundee': 'DUN',
        'Ross County': 'ROS',
        'Rangers': 'RAN',
        'Aberdeen': 'ABE',
        'ST Johnstone': 'STJ',
      };
      
    
      const renderTeamInitials = (teamName) => {
        return teamInitials[teamName] || teamName;
      };
    
      const teamLogos = {
        'Aberdeen': Aberdeen,
        'Celtic': Celtic,
        'Heart Of Midlothian': Hearts,
        'Motherwell': Motherwell,
        'Dundee': Dundee,
        'Kilmarnock': Kilmarnock,
        'Rangers': Rangers,
        'Hibernian': Hibernian,
        'ST Johnstone': StJohnstone,
        'Ross County': RossCounty,
        'ST Mirren': StMirren,
        'Dundee Utd': DundeeUtd,
    };
    
    const renderTeamLogo = (teamName) => {
    const logo = teamLogos[teamName];
    if (logo) {
      return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
    }
    return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
    };
    return (
        <div>
            <div className=''>
                <h2 className="text-dark text-center padtop">Scottish Premiership Fixtures</h2>
                <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
                    <div className="dropdown d-inline-block" style={{ paddingRight: '' }}>
                        <button style={{ marginRight: '', marginBottom: '5px' }} className="btn btn-custom dropdown-toggle" type="button" id="groupStageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            {buttonLabel}
                        </button>
                        <ul className="dropdown-menu text-center custom" aria-labelledby="groupStageDropdown">
                            {dateRanges.map((range, index) => (
                                <li key={index}>
                                    <button className="dropdown-item" onClick={() => handlePageChange(index + 1)}>
                                        Round {index + 1}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="">
                    <div className="row justify-content-center">
                        {filteredFixtures.length === 0 && <p className="text-center">No fixtures available for this Round.</p>}
                        {filteredFixtures.map(fixture => (
                            <div key={fixture.fixture.id} className="col-lg-12 mx-auto mb-3">
                                <div className="card" style={{ backgroundColor: '#fff' }}>
                                    <b className="text-center padtop mb-3">{fixture.teams.home.name} vs {fixture.teams.away.name} </b>
                                    <div className="row">
                                        <div className="col text-center">
                                            <span style={{ fontStyle: 'normal' }}>
                                            {renderTeamLogo(fixture.teams.home.name)}
                                            </span>
                                        </div>
                                        <div className="col padtop1">
                                            <input
                                                className="form-control text-center fs-3 border-secondary"
                                                style={{ backgroundColor: '#fff' }}
                                                value={fixture.fixture.status.short === 'FT' || fixture.fixture.status.short === 'AET' || fixture.fixture.status.short === 'PEN' ? fixture.goals.home : 'TBD'}
                                                disabled
                                            />
                                        </div>
                                        <div className="col padtop1">
                                            <input
                                                className="form-control text-center fs-3 border-secondary"
                                                style={{ backgroundColor: '#fff' }}
                                                value={fixture.fixture.status.short === 'FT' || fixture.fixture.status.short === 'AET' || fixture.fixture.status.short === 'PEN' ? fixture.goals.away : 'TBD'}
                                                disabled
                                            />
                                        </div>
                                        <div className="col text-center">
                                            <span style={{ fontStyle: 'normal' }}>
                                            {renderTeamLogo(fixture.teams.away.name)}
                                            </span>
                                        </div>
                                    </div>
                                    {/**Separate rows*/}
                                    <div className="row">
                                        <div className="col text-center">
                                            
                                            <span style={{ fontStyle: 'normal' }}>
                                            <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                                            </span>
                                        </div>
                                        <div className="col padtop1">
                                         
                                        </div>
                                        <div className="col padtop1">
                                         
                                        </div>
                                        <div className="col text-center">
                                            <span style={{ fontStyle: 'normal' }}>
                                            <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                                            </span>
                                        </div>
                                    </div>
                                    <h5 className='text-center'>{fixture.fixture.status.short === 'PEN' ? `Penalties (${fixture.score.penalty.home} - ${fixture.goals.away}) ` : ''} </h5>
                                    <p className="text-center"> {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                    {fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'FT' && fixture.fixture.status.short !== 'AET' && fixture.fixture.status.short !== 'PEN' && (
                                        <p className="text-center text-success padtop">Live - {fixture.fixture.status.elapsed}'</p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScottishFixturesBracket;
