import React, { useEffect } from 'react';

function AdComponent() {
  useEffect(() => {
    // Ensure the adsbygoogle array is initialized
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div>
      <h1>Welcome to My Website</h1>
      {/* AdSense Ad Unit */}
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-7976312343222669" // Your AdSense Publisher ID
           data-ad-slot="9895220901"  // Your unique Ad slot ID
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
      <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
      <p>Some other content...</p>
    </div>
  );
}

export default AdComponent;
