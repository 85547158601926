import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ChampionshipStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};

    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
      standingsMap[fixture.teams.away.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
    });

    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }

      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;

      // Determine match result
      let homePoints = 0;
      let awayPoints = 0;
      let homeResult = "";
      let awayResult = "";

      if (homeGoals > awayGoals) {
        homePoints = 3;
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        awayPoints = 3;
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homePoints = 1;
        awayPoints = 1;
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }

      // Update standings
      standingsMap[homeTeam].points += homePoints;
      standingsMap[awayTeam].points += awayPoints;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
    });

    // Convert standings map to array
    const standingsArray = Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order

    return standingsArray;
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  return (
    <div className=''>
      <div className='App-headerV3'>
        <Navbar />
        <h2 className='text-center text-dark padtop mb-1'>Championship Standings</h2>
        <hr className="w-40 mx-auto mb-3 mb-xl-9 border-dark-subtle" />
        <div className='container text-center mb-2'><i className='text-center'>*Click on team name to view stats </i></div>

        <div className='d-flex justify-content-center '>
  <div className='col-6 col-md-3  text-center'>
    <select 
      className='form-select mb-3 text-light text-center ' 
      style={{ backgroundColor: 'purple', color: 'white' }}

      value={season} 
      onChange={(e) => setSeason(e.target.value)}
    >
      <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>Championship 24/25</option>
      <option value="2023" style={{ backgroundColor: 'white', color: 'black' }}>Championship 23/24</option>
      <option value="2022" style={{ backgroundColor: 'white', color: 'black' }}>Championship 22/23</option>
      <option value="2021" style={{ backgroundColor: 'white', color: 'black' }}>Championship 21/22</option>
      <option value="2020" style={{ backgroundColor: 'white', color: 'black' }}>Championship 20/21</option>


    </select>
  </div>
</div>


        <div className='container'>
          {loading ? (
            <h2 className='text-center text-dark'><Spinner /></h2>
          ) : (
            <table className="table table table-striped" style={{ backgroundColor: '' }}>
              <thead className='' style={{ backgroundColor: '', color: 'black' }}>
                <tr>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>#</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>Team</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>MP</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>W</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>D</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>L</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>Pts</th>
                </tr>
              </thead>
              <tbody >
                {standings.map((team, index) => (
                  <tr key={index}>
                    <th scope="row" className={index < 2 ? 'text-primary' : index >= standings.length - 3 ? 'text-danger' : index == 2 ? 'text-warning' :  index == 3 ? 'text-warning' : index == 4 ? 'text-warning' : index == 5 ? 'text-warning' :  ''} style={{ backgroundColor: '', color: 'black' }}>{index + 1}</th>
                    <td style={{ backgroundColor: '', color: 'black' }}>
                      {team.team === 'Manchester United' ? (
                        <Link to="/manunited" style={{ textDecoration: 'none', color: 'black' }}>
                          Man Utd
                        </Link>
                      ) : team.team === 'Blackburn' ? (
                        <Link to="/Blackburn" style={{ textDecoration: 'none', color: 'black' }}>Blackburn</Link>
                      ) : team.team === 'Bristol City' ? (
                        <Link to="/bristol" style={{ textDecoration: 'none', color: 'black' }}>Bristol</Link>
                      ) : team.team === 'Burnley' ? (
                        <Link to="/Burnley" style={{ textDecoration: 'none', color: 'black' }}>Burnley</Link>
                      ) : team.team === 'Cardiff' ? (
                        <Link to="/cardiff" style={{ textDecoration: 'none', color: 'black' }}>Cardiff</Link>
                      ) : team.team === 'Coventry' ? (
                        <Link to="/coventry" style={{ textDecoration: 'none', color: 'black' }}>Coventry</Link>
                      ) : team.team === 'Derby' ? (
                        <Link to="/Derby" style={{ textDecoration: 'none', color: 'black' }}>Derby </Link>
                      ) : team.team === 'Hull City' ? (
                        <Link to="/hull" style={{ textDecoration: 'none', color: 'black' }}>Hull</Link>
                      ) : team.team === 'Leeds' ? (
                        <Link to="/leeds" style={{ textDecoration: 'none', color: 'black' }}>Leeds</Link>
                      ) : team.team === 'Luton' ? (
                        <Link to="/luton" style={{ textDecoration: 'none', color: 'black' }}>Luton</Link>
                      ) : team.team === 'Middlesbrough' ? (
                        <Link to="/middlesbrough" style={{ textDecoration: 'none', color: 'black' }}>Middlesbrough</Link>
                      ) : team.team === 'Millwall' ? (
                        <Link to="/millwall" style={{ textDecoration: 'none', color: 'black' }}>Millwall</Link>
                      ) : team.team === 'Norwich' ? (
                        <Link to="/Norwich" style={{ textDecoration: 'none', color: 'black' }}>Norwich</Link>
                      ) : team.team === 'Oxford United' ? (
                        <Link to="/Oxford " style={{ textDecoration: 'none', color: 'black' }}>Oxford </Link>
                      ) : team.team === 'Plymouth' ? (
                        <Link to="/Plymouth" style={{ textDecoration: 'none', color: 'black' }}>Plymouth</Link>
                      ) : team.team === 'Portsmouth' ? (
                        <Link to="/Portsmouth" style={{ textDecoration: 'none', color: 'black' }}>Portsmouth</Link>
                      ) : team.team === 'Preston' ? (
                        <Link to="/preston" style={{ textDecoration: 'none', color: 'black' }}>Preston</Link>
                      ) : team.team === 'QPR' ? (
                        <Link to="/QPR" style={{ textDecoration: 'none', color: 'black' }}>QPR</Link>
                      ) : team.team === 'Sunderland' ? (
                        <Link to="/Sunderland" style={{ textDecoration: 'none', color: 'black' }}>Sunderland</Link>
                      ) : team.team === 'Sheffield Wednesday' ? (
                        <Link to="/sheffieldwed" style={{ textDecoration: 'none', color: 'black' }}>Sheffield Wed</Link>
                      ) : team.team === 'Stoke City' ? (
                        <Link to="/Stoke" style={{ textDecoration: 'none', color: 'black' }}>Stoke</Link>
                      ) : team.team === 'Swansea' ? (
                        <Link to="/Swansea" style={{ textDecoration: 'none', color: 'black' }}>Swansea</Link>
                      ) : team.team === 'Watford' ? (
                        <Link to="/Watford" style={{ textDecoration: 'none', color: 'black' }}>Watford</Link>
                      ) :  team.team === 'West Brom' ? (
                        <Link to="/WestBrom" style={{ textDecoration: 'none', color: 'black' }}>West Brom</Link>
                      ) :  team.team === 'Sheffield Utd' ? (
                        <Link to="/SheffieldUtd" style={{ textDecoration: 'none', color: 'black' }}>Sheffield Utd</Link>
                     ) : (
                        team.team
                      )}
                    </td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.matchesPlayed}</td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.wins}</td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.draws}</td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.losses}</td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className='d-flex justify-content-center'>
            <div className='text-center text-primary'>| </div>
            <div className='text-dark'>Promotion</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-warning'>| </div>
            <div className='text-dark'>Play Off</div>
          </div>
        
          <div className='d-flex justify-content-center'>
            <div className='text-center text-danger'>| </div>
            <div className='text-dark'>Relegation</div>
          </div>
        </div>
        <div className='container'>
          <div className="text- mt-4" style={{ paddingBottom: '5px' }}>
            <button className="btn btn-outline-dark" onClick={handleGoBack}>Go Back</button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ChampionshipStandings;
