import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { useState, useEffect, useRef, useMemo } from 'react';
import { imageDb, useAuth, db } from '../firebase';
import {  updateProfile } from 'firebase/auth';
import { ref, getStorage, uploadBytes, getDownloadURL} from 'firebase/storage';
import { v4 } from "uuid"
import Invitations from '../Invitations';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getDatabase, get, set, onValue } from 'firebase/database';
import { useTranslation } from 'react-i18next';
import Germany from '../EURO-2024-Team-Kits/Germany.png';


function UserProfile({ navigate }) {

    const { auth } = useAuth(); // Destructure auth from the useAuth hook
    // this is used so firebase can get the user uid and see if it matches so that it can then allow the user to see their details respectfully
    const [user, setUser] = useState(null);
    const [ memberSince, setMemberSince] = useState('')
    const [image, setImage] = useState('')
    const storage = getStorage(auth.app);
    const [img, setImg] = useState('')
    const [authUser, setAuthUser] = useState(null);
    const database = getDatabase()
    const [ setTotalPoints] = useState(0);
    const [userId, setUserId] = useState(null); // Initially, userId is null
    const [participantPoints, setParticipantPoints] = useState({});
    const [participants, setParticipants] = useState([]);
    const { t } = useTranslation();
    const [filteredFixtures, setFilteredFixtures] = useState([]);
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const intervalRefs = useRef({});
    const [fixturePoints, setFixturePoints] = useState({});
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
              setUserId(user.uid); // Ensure this value is valid
          } else {
              console.log("No user is logged in.");
          }
      });
  
      return () => unsubscribe();
  }, []);
  


    useEffect(() => {
        // Set up a listener for changes in authentication state
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          setUser(user); // Update the user state when the authentication state changes

          if (user) {
            const creationTime = user.metadata.creationTime;
            setMemberSince(new Date(creationTime).toLocaleDateString());
          }
      
        });

        // Clean up the listener when the component unmounts
        // unsubscribe is being used to make sure there are no errors regarding the authentication of the user
        return () => unsubscribe();
      }, [auth]); 

      const extractNameOnlyFromDisplayName = (displayName) => {
        if (!displayName) {
          return ''; // Handle the case where displayName is null or undefined
        }
      
        // Display only the User's name. The only data that is not in ()
        const match = displayName.match(/^[^(]+/);
      
        // Check if there is a match and return the content outside parentheses
        return match ? match[0].trim() : displayName;
      };

     

      const extractSurnameFromDisplayName = (displayName) => {
        if (!displayName) {
          return '';
        }
      
        // Using regular expression to extract content inside the second set of parentheses (surname)
        const match = displayName.match(/\([^)]+\)\s\(([^)]+)\)/);
      
        return match ? match[1] : '';
      };

      // a function to extract only the country and display it
      const extractCountryFromDisplayName = (displayName) => {
        if (!displayName) {
          return '';
        }
      
        // Using regular expression to extract content inside the second set of parentheses (counrty)
        const match = displayName.match(/\([^)]+\)\s\([^)]+\)\s\(([^)]+)\)/ );
      
        return match ? match[1] : '';
      };

      const extractFavSportFromDisplayName = (displayName) => {
        if (!displayName) {
          return '';
        }
      
        // Regular expression to extract content inside the fourth set of parentheses
        const match = displayName.match(/\((?:[^)]+)\)\s*\((?:[^)]+)\)\s*\((?:[^)]+)\)\s*\(([^)]+)\)/);
      
        return match ? match[1] : '';
      };
      
      const extractFavTeamFromDisplayName = (displayName) => {
        if (!displayName) {
          return '';
        }
      
        // Regular expression to extract content inside the fifth set of parentheses
        const match = displayName.match(/\((?:[^)]+)\)\s*\((?:[^)]+)\)\s*\((?:[^)]+)\)\s*\((?:[^)]+)\)\s*\(([^)]+)\)/);
      
        return match ? match[1] : '';
      };
      


    // Extract the username from the first set of parentheses in the displayName
    const extractUsernameFromDisplayName = (displayName) => {
        if (!displayName) {
            return ''; // Handle the case where displayName is null or undefined
        }

        // Using regular expression to extract content inside the first set of parentheses
        const match = displayName.match(/\(([^)]+)\)/);

        // Check if there is a match and return the content inside the first set of parentheses
        return match ? match[1] : displayName;
    };


      // functions for profile pic

      useEffect(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user);
          } else {
            // Handle signed-out state
          }
        });
      }, []);
    
      const handleImageUpload = async (e) => {
        if (!user) {
          return;
        }
    
        const file = e.target.files[0];
    
        if (!file) {
          return;
        }
    
        const storageRef = ref(storage, `profile-pics/${user.email}`); // Store image under user's UID
    
        try {
          await uploadBytes(storageRef, file);
          const imageURL = await getDownloadURL(storageRef);
    
          // Update the user's profile with the image URL
          await updateProfile(auth.currentUser, { photoURL: imageURL });
    
          setImage(imageURL);
        } catch (error) {
          console.error('Image upload error:', error);
        }
      }
      console.log('Profile Picture URL:', image);

      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setAuthUser(user);
          } else {
            setAuthUser(null);
          }
        });
    
        return () => unsubscribe();
      }, []);
    
      const userSignOut = () => {
        signOut(auth).then(() => {
          console.log('signed out');
          navigate('/login');
        }).catch(error => console.log(error));
      };
    

      const handleViewMore = () => {
        setShowAll(true);
    };


    // put req
    // const [name, setName] = useState('');
    // const [isNameSet, setIsNameSet] = useState(false);

    // // Listen for authentication state changes and set the userId
    // useEffect(() => {
    //     const unsubscribe = onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             setUserId(user.uid); // Set the user ID when the user is authenticated
    //         } else {
    //             // Handle the case where no user is logged in
    //             console.log("No user is logged in.");
    //         }
    //     });

    //     // Cleanup subscription on unmount
    //     return () => unsubscribe();
    // }, []);

    // // Fetch the user's name from the database when userId is available
    // useEffect(() => {
    //     if (userId) {
    //         const userReff = ref(db, `NameTable/${userId}`);
    //         onValue(userReff, (snapshot) => {
    //             const data = snapshot.val();
    //             if (data) {
    //                 setName(data);
    //                 setIsNameSet(true);
    //             }
    //         });
    //     }
    // }, [userId]);

    // // Function to handle the change in the input field
    // const handleChange = (e) => {
    //     setName(e.target.value);
    // };

    // // Function to save the name to Firebase
    // const saveName = () => {
    //     if (userId) {
    //         const userRef = ref(db, `NameTable/${userId}`);
    //         set(userRef, name); // Save the name under the user's ID
    //         setIsNameSet(true); // Set the flag to true to indicate that the name has been saved
    //     } else {
    //         console.error('No user ID found. Make sure the user is logged in.');
    //     }
    // };
    // put req


  return (
    <div>
        <Navbar user={user} profilePicture={image}/>
       <div className='App-headerV3'>
        {user && (
              <div>
      
    
              </div>
            )}
            
      <section class="bg-light py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center">{t('your_profile')}</h2>
        {/* <p class="text-secondary text-center lead fs-4 mb-5">The Profile page is your digital hub, where you can fine-tune your experience. Here's a closer look at the settings you can expect to find in your profile page.</p> */}
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row gy-4 gy-lg-0">
      <div class="col-12 col-lg-4 col-xl-3">
        <div class="row gy-4">
        <div className="col-12">
      <div className="card widget-card border-light shadow-sm">
        <div className="card-header purple-bg text-light text-center">{t('welcomep')} {extractUsernameFromDisplayName(user?.displayName)}</div>
        <div className="card-body">
          <div className="text-center mb-3">
            {user && (
              <div className="d-flex justify-content-center mb-2">
                <span className="text-white border border-3 border-primary-subtle rounded-circle d-flex align-items-center justify-content-center">
                  {user.photoURL ? (
                    <img
                      src={user.photoURL}
                      alt="Profile"
                      className="avatar default-profile-picture"
                    />
                  ) : (
                    <img
                      src="https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg"
                      alt="Default Profile"
                      className="avatar default-profile-picture"
                    />
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="text-center mb-3">
            <label className="mb-0 fs-7 text-dark">
            {t('edit_profile_picture')}
                          <input type='file' accept='image/*' onChange={handleImageUpload} style={{ display: 'none' }} />
            </label>
          </div>
          <h5 className="text-center mb-1">{extractNameOnlyFromDisplayName(user?.displayName)} {extractSurnameFromDisplayName(user?.displayName)}</h5>
          <div className='text-center padtopinput'>
            <Link to='/'>
              <button className='btn btn-outline-danger text-center' onClick={userSignOut}>
              {t('sign_out')}              </button>
            </Link>
          </div>
   
        </div>
      </div>
    </div>
        
          <div class="col-12">
            <div class="card widget-card border-light shadow-sm">
              <div class="card-header purple-bg text-light text-center">{t('about_me')}</div>
              <div class="card-body">
                <ul class="list-group list-group-flush mb-0">
                  <li class="list-group-item">
                    <h6 class="mb-1">
                      <span class=" bi bi-mailbox me-2"></span>
                      Email
                    </h6>
                    <span>{user?.email}</span>
                  </li>
                  <li class="list-group-item">
                    <h6 class="mb-1">
                      <span class="bii bi-geo-alt-fill me-2"></span>
                      {t('location')}
                    </h6>
                    <span>{extractCountryFromDisplayName(user?.displayName)}</span>
                  </li>
                  <li class="list-group-item">
                    <h6 class="mb-1">
                      <span class="bii bi-building-fill-gear me-2"></span>
                      {t('date_joined')}                    </h6>
                    <span>{new Date(memberSince).toLocaleDateString('en-GB')}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        
        </div>
      </div>
      <div class="col-12 col-lg-8 col-xl-9">
        <div class="card widget-card border-light shadow-sm">
          <div class="card-body p-4">
            <ul class="nav nav-tabs" id="profileTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">Overview</button>
              </li>
              {/* <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Profile</button>
              </li> */}
              {/* <li class="nav-item" role="presentation">
                <button class="nav-link" id="email-tab" data-bs-toggle="tab" data-bs-target="#email-tab-pane" type="button" role="tab" aria-controls="email-tab-pane" aria-selected="false">Emails</button>
              </li> */}
           
            </ul>
            <div class="tab-content pt-4" id="profileTabContent">
              <div class="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabindex="0">
                {/* <h5 class="mb-3">About</h5> */}
                {/* <p class="lead mb-3">Ethan Leo is a seasoned and results-driven Project Manager who brings experience and expertise to project management. With a proven track record of successfully delivering complex projects on time and within budget, Ethan Leo is the go-to professional for organizations seeking efficient and effective project leadership.</p> */}
                {/* <h5 class="mb-3">Profile</h5> */}
                <div class="row g-0">
                  <div class="col-6 col-md-6 bg-light border-bottom border-white border-3">
                    <div class="p-2 text-center"><u>Favourite Sport</u></div>
                  </div>
               
                  <div class="col-6 col-md-6 bg-light border-bottom border-white border-3">
                    <div class="p-2 text-bold text-center"><u>Favourite Team</u></div>
                  </div>
                </div>
                <div class="row g-0">
                <div class="col-6 col-md-6 bg-light border-bottom border-white border-3 d-flex justify-content-between align-items-center">
  <div class="px-2 text-bold">Favourite Sport: </div>
  <div class="px-2 text-end">{extractFavSportFromDisplayName(user?.displayName)}</div>
</div>
               
                  <div class="col-6 col-md-6 bg-light border-bottom border-white border-3 d-flex justify-content-between align-items-center ">
  <div class="px-2 text-bold">Favourite Team:                   
</div>
  <div class="px-2 text-end ">{extractFavTeamFromDisplayName(user?.displayName)}</div>
</div>


                </div>
              </div>

              {/* Put req */}
              {/* <div>
            <h1>{isNameSet ? 'Update your name:' : 'Enter your name:'}</h1>
            <input type="text" value={name} onChange={handleChange} />
            <button onClick={saveName}>{isNameSet ? 'Update Name' : 'Save Name'}</button>
            
            {isNameSet && (
                <>
                    <h2>Your Saved Name:</h2>
                    <p>{name}</p>
                </>
            )}
        </div> */}
                  {/* Put req */}

              <br />
              <b className='text-start'><Link to='/passreset'>{t('change_password')}</Link></b>
         
           
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
</div>

        <Footer />
        </div>
  )
}

export default UserProfile