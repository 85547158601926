import Alaves from '../Team-Kits-Images/Alaves.png'
import Athleti from '../Team-Kits-Images/Athleti.png'
import Athletic from '../Team-Kits-Images/Athletic.png'
import Barcelona from '../Team-Kits-Images/Barcelona.png'
import Betis from '../Team-Kits-Images/Betis.png'
import CeltaVigo from '../Team-Kits-Images/CeltaVigo.png'
import Espanyol from '../Team-Kits-Images/Espanyol.png'
import Getafe from '../Team-Kits-Images/Getafe.png'
import Girona from '../Team-Kits-Images/Girona.png'
import LasPalmas from '../Team-Kits-Images/LasPalmas.png'
import Leganes from '../Team-Kits-Images/Leganes.png'
import Madrid from '../Team-Kits-Images/Madrid.png'
import Mallorca from '../Team-Kits-Images/Mallorca.png'
import Osasuna from '../Team-Kits-Images/Osasuna.png'
import Rayo from '../Team-Kits-Images/Rayo.png'
import Sevilla from '../Team-Kits-Images/Sevilla.png'
import Sociedad from '../Team-Kits-Images/Sociedad.png'
import Valencia from '../Team-Kits-Images/Valencia.png'
import Valladolid from '../Team-Kits-Images/Valladolid.png'
import Villareal from '../Team-Kits-Images/Villareal.png'

import { useTranslation } from 'react-i18next';
import Standings from './Standings'
import { runTransaction } from 'firebase/firestore'


import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Confetti from 'react-confetti';
import LaLigaStandingsPicks from './LaLigaStandingsPicks'
 
function LaLigaPicks() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');

  const fetchTotalPoints = async (displayName) => {
    try {
      const db = getDatabase();
      const totalPointsRef = ref(db, `LaLiga2024TotalPoints/${displayName}`);
      const totalPointsSnapshot = await get(totalPointsRef);
      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  const fetchPicks = async (displayName) => {
    try {
      const db = getDatabase();
      const picksRef = ref(db, `LaLiga2024Picks/${displayName}`);
      const totalPointsRef = ref(db, `LaLiga2024TotalPoints/${displayName}`);

      const [picksSnapshot, totalPointsSnapshot] = await Promise.all([
        get(picksRef),
        get(totalPointsRef)
      ]);

      const picksData = picksSnapshot.val() || {};
      const userPicks = picksData || {};
      setInputsData(userPicks);
      setDisplayName(displayName);

      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);

      const pointsData = {};
      Object.keys(userPicks).forEach(fixtureId => {
        pointsData[fixtureId] = userPicks[fixtureId].points;
      });
      setFixturePoints(pointsData);

      // Start point incrementing intervals for the fetched picks
      Object.keys(userPicks).forEach(fixtureId => {
        if (!intervalRefs.current[fixtureId]) {
          startPointsIncrement(fixtureId);
        }
      });
    } catch (error) {
      console.error("Error fetching picks:", error);
    }
  };

  const [dateRanges] = useState([
{ start: '2024-06-11', end: '2024-08-20' },  // Matchweek 1
{ start: '2024-08-20', end: '2024-08-26' },  // Matchweek 2
{ start: '2024-08-26', end: '2024-09-02' },  // Matchweek 3
{ start: '2024-09-02', end: '2024-09-16' },  // Matchweek 4
{ start: '2024-09-16', end: '2024-09-23' },  // Matchweek 5
{ start: '2024-09-23', end: '2024-09-31' },  // Matchweek 6
{ start: '2024-09-31', end: '2024-10-07' },  // Matchweek 7
{ start: '2024-10-07', end: '2024-10-22' },  // Matchweek 8
{ start: '2024-10-22', end: '2024-10-28' },  // Matchweek 9
{ start: '2024-10-28', end: '2024-11-03' },  // Matchweek 10
{ start: '2024-11-03', end: '2024-11-10' },  // Matchweek 11
{ start: '2024-11-10', end: '2024-11-24' },  // Matchweek 12
{ start: '2024-11-24', end: '2024-12-01' },  // Matchweek 13
{ start: '2024-12-01', end: '2024-12-05' },  // Matchweek 14
{ start: '2024-12-05', end: '2024-12-08' },  // Matchweek 15
{ start: '2024-12-08', end: '2024-12-15' },  // Matchweek 16
{ start: '2024-12-15', end: '2024-12-22' },  // Matchweek 17
{ start: '2024-12-22', end: '2024-12-27' },  // Matchweek 18
{ start: '2024-12-27', end: '2024-12-30' },  // Matchweek 19
{ start: '2025-01-01', end: '2025-01-06' },  // Matchweek 20
{ start: '2025-01-06', end: '2025-01-16' },  // Matchweek 21
{ start: '2025-01-16', end: '2025-01-19' },  // Matchweek 22
{ start: '2025-01-19', end: '2025-01-26' },  // Matchweek 23
{ start: '2025-01-26', end: '2025-02-02' },  // Matchweek 24
{ start: '2025-02-02', end: '2025-02-16' },  // Matchweek 25
{ start: '2025-02-16', end: '2025-02-23' },  // Matchweek 26
{ start: '2025-02-23', end: '2025-02-27' },  // Matchweek 27
{ start: '2025-02-27', end: '2025-03-09' },  // Matchweek 28
{ start: '2025-03-09', end: '2025-03-16' },  // Matchweek 29
{ start: '2025-03-16', end: '2025-04-03' },  // Matchweek 30
{ start: '2025-04-03', end: '2025-04-06' },  // Matchweek 31
{ start: '2025-04-06', end: '2025-04-13' },  // Matchweek 32
{ start: '2025-04-13', end: '2025-04-20' },  // Matchweek 33
{ start: '2025-04-20', end: '2025-04-27' },  // Matchweek 34
{ start: '2025-04-27', end: '2025-05-04' },  // Matchweek 35
{ start: '2025-05-04', end: '2025-05-11' },  // Matchweek 36
{ start: '2025-05-11', end: '2025-05-19' },  // Matchweek 37
{ start: '2025-05-19', end: '2025-07-20' },  // Matchweek 38
    // Add more matchweeks as needed
]);

useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);


  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    if (user) {
      const displayName = user.displayName || 'Anonymous';
      fetchPicks(displayName);
      fetchTotalPoints(displayName);
    }
  }, [user]);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/140/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  const handleInputChange = (fixtureId, team, value) => {
    setInputsData(prevInputsData => ({
      ...prevInputsData,
      [fixtureId]: {
        ...prevInputsData[fixtureId],
        [team]: value,
        points: prevInputsData[fixtureId]?.points || 0,
        pointsAwarded: prevInputsData[fixtureId]?.pointsAwarded || false
      }
    }));
  };


useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);


const awardPointsForAllUsers = async (fixtureId) => {
  try {
    const db = getDatabase();
    const fixtureRef = ref(db, `fixtures/${fixtureId}`);
    const fixtureSnapshot = await get(fixtureRef);
    const fixtureData = fixtureSnapshot.val();

    console.log("Fixture Data:", fixtureData); // Debug: Log fixture data

    if (fixtureData && fixtureData.fixture.status.short === "FT") {
      const homeGoals = fixtureData.score.fulltime.home;
      const awayGoals = fixtureData.score.fulltime.away;

      const picksRef = ref(db, `LaLiga2024Picks`);
      const picksSnapshot = await get(picksRef);
      const allUserPicks = picksSnapshot.val() || {};

      Object.keys(allUserPicks).forEach(async (userId) => {
        const userPicks = allUserPicks[userId];
        const userPick = userPicks[fixtureId];

        if (userPick && !userPick.pointsAwarded) {
          const { home: predictedHomeGoals, away: predictedAwayGoals } = userPick;

          let points = 0;

          if (predictedHomeGoals == homeGoals && predictedAwayGoals == awayGoals) {
            points = 3;
          } else if (
            (predictedHomeGoals > predictedAwayGoals && homeGoals > awayGoals) ||
            (predictedHomeGoals < predictedAwayGoals && homeGoals < awayGoals) ||
            (predictedHomeGoals == predictedAwayGoals && homeGoals == awayGoals)
          ) {
            points = 1;
          }

          console.log(`Awarding ${points} points to user ${userId} for fixture ${fixtureId}`); // Debug: Log points awarded

          userPicks[fixtureId] = {
            ...userPick,
            points: points,
            pointsAwarded: true,
          };

          const userTotalPointsRef = ref(db, `LaLiga2024TotalPoints/${userId}`);
          const userTotalPointsSnapshot = await get(userTotalPointsRef);
          const userTotalPoints = userTotalPointsSnapshot.val() || { totalPoints: 0 };

          await set(userTotalPointsRef, {
            totalPoints: userTotalPoints.totalPoints + points,
          });

          await set(ref(db, `LaLiga2024Picks/${userId}`), userPicks);
        }
      });
    }
  } catch (error) {
    console.error("Error awarding points:", error);
  }
};



const startPointsIncrement = (fixtureId) => {
  intervalRefs.current[fixtureId] = setInterval(() => {
    awardPointsForAllUsers(fixtureId);
  }, 60000); // Check every 60 seconds
};

const stopPointsIncrement = (fixtureId) => {
  if (intervalRefs.current[fixtureId]) {
    clearInterval(intervalRefs.current[fixtureId]);
    delete intervalRefs.current[fixtureId];
  }
};

// Ensure that this function is called every 60 seconds to check for completed fixtures
useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);

  const handleSubmit = async () => {
    if (userId) {
      const db = getDatabase();
      const picksRef = ref(db, `LaLiga2024Picks/${displayName}`);
      const totalPointsRef = ref(db, `LaLiga2024TotalPoints/${displayName}`);

      // Extract displayName and default to 'Anonymous' if not available
      let userDisplayName = user.displayName || 'Anonymous';

      // Use regex to extract the first item in the parentheses
      const match = userDisplayName.match(/\(([^)]+)\)/);
      if (match && match[1]) {
        userDisplayName = match[1];
      }

      try {
        // Fetch existing picks from the backend
        const picksSnapshot = await get(picksRef);
        const existingPicksData = picksSnapshot.val() || {};

        // Merge new picks with existing picks
        const mergedPicks = {
          ...existingPicksData,
          ...inputsData
        };

        const dataToSubmit = mergedPicks;

        console.log("Submitting data:", dataToSubmit);
        await set(picksRef, dataToSubmit);

        // Check if totalPoints entry exists, if not, initialize it
        const totalPointsSnapshot = await get(totalPointsRef);
        if (!totalPointsSnapshot.exists()) {
          await set(totalPointsRef, { totalPoints: 0 });
        }

        setSent(true);
        setTimeout(() => setSent(false), 15000);
        setConfetti(true);
        setTimeout(() => setConfetti(false), 5000);

        Object.keys(inputsData).forEach(fixtureId => {
          startPointsIncrement(fixtureId);
        });
      } catch (error) {
        console.error('Error submitting picks:', error);
        setError(true);
        setTimeout(() => setError(false), 5000);
      }
    }
  };

  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(Round);
    handlePageChange(index + 1);
  };
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamLogos = {
    'Getafe': Getafe,
    'Athletic Club': Athletic,
    'Real Betis': Betis,
    'Girona': Girona,
    'Mallorca': Mallorca,
    'Real Madrid': Madrid,
    'Las Palmas': LasPalmas,
    'Sevilla': Sevilla,
    'Villarreal': Villareal,
    'Atletico Madrid': Athleti,
    'Valencia': Valencia,
    'Barcelona': Barcelona,
    'Real Sociedad': Sociedad,
    'Rayo Vallecano': Rayo,
    'Celta Vigo': CeltaVigo,
    'Alaves': Alaves,
    'Osasuna': Osasuna,
    'Leganes': Leganes,
    'Espanyol': Espanyol,
    'Valladolid': Valladolid,


   
  };
  
  const teamInitials = {
   'Getafe': 'GET',
'Athletic Club': 'ATH',
'Real Betis': 'BET',
'Girona': 'GIR',
'Mallorca': 'MAL',
'Real Madrid': 'RM',
'Las Palmas': 'LAS',
'Sevilla': 'SEV',
'Villarreal': 'VIL',
'Atletico Madrid': 'ATM',
'Valencia': 'VAL',
'Barcelona': 'BAR',
'Real Sociedad': 'SOC',
'Rayo Vallecano': 'RAY',
'Celta Vigo': 'CEL',
'Alaves': 'ALA',
'Osasuna': 'OSA',
'Leganes': 'LEG',
'Espanyol': 'ESP',
'Valladolid': 'VAL'

   
  };
  
  const renderTeamLogo = (teamName) => {
    const logo = teamLogos[teamName];
    if (logo) {
      return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
    }
    return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
  };
  
  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  return (
    <div>
    <Navbar />
    <div className='App-headerV3'>
      <h2 className='text-dark text-center padtop'>La Liga Picks</h2>
      <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
        <div className="dropdown d-inline-block">
          <button
            className="btn btn-purple dropdown-toggle"
            type="button"
            id="groupStageDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedMatchday}
          </button>
          <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
            <ul className="list-group">
              {dateRanges.map((range, index) => (
                <li key={index} className="list-group-item">
                       <button className="dropdown-item" onClick={() => handleMatchdaySelect(index)}>
                    Round {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
  
      <div className='container'>
        <div className='row'>
          {/* Standings Card for large screens */}
          <div className='col-lg-5 d-none d-lg-flex align-items-start justify-content-end standings-card-margin' style={{ paddingTop: '15px' }}>
  <div className='card'>
    <div className='card-body'>
      <h5 className='card-title'><LaLigaStandingsPicks /></h5>
    </div>
  </div>
</div>
          {/* Main content area */}
          <div className='col-lg-7 col-12'>
            {filteredFixtures.map(fixture => (
              <div key={fixture.fixture.id} className='my-3 card'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  <div className="row align-items-center">
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                    <div className="col text-center padtopinput border-secondary">
  <div className='form-group border-secondary text-center'>
    <select
      value={inputsData[fixture.fixture.id]?.home ?? ''}
      onChange={(e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 10) {
          handleInputChange(fixture.fixture.id, 'home', value);
        } else {
          handleInputChange(fixture.fixture.id, 'home', '');
        }
      }}
      className='form-control text-center border-secondary'
      disabled={fixture.fixture.status.short !== 'NS'}
      style={{ textAlign: 'center', textAlignLast: 'center' }} // Inline styles for centering text
    >
      <option value="">Pick</option>
      {[...Array(11).keys()].map(num => (
        <option key={num} value={num}>{num}</option>
      ))}
    </select>
  </div>
</div>
                    <div className="col text-center padtopinput border-secondary">
                      <div className='form-group border-secondary text-center'>
                        <select
                          value={inputsData[fixture.fixture.id]?.away ?? ''}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10);
                            if (!isNaN(value) && value >= 0 && value <= 10) {
                              handleInputChange(fixture.fixture.id, 'away', value);
                            } else {
                              handleInputChange(fixture.fixture.id, 'away', '');
                            }
                          }}
                          className='form-control text-center border-secondary'
                          disabled={fixture.fixture.status.short !== 'NS'}
                          style={{ textAlign: 'center', textAlignLast: 'center' }} // Inline styles for centering text
                        >
                          <option value="">Pick</option>
                          {[...Array(11).keys()].map(num => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
                  <div>Result: {fixture.fixture.status.short === 'NS' ? 'TBA' : `${fixture.goals.home} - ${fixture.goals.away}`}</div>
                  <p className="text-center">{convertToFormattedDateTime(fixture.fixture.date)}</p>
                  {fixture.fixture.status.short === 'FT' && (
                    <p className={inputsData[fixture.fixture.id]?.points === 1 ? 'text-warning' : inputsData[fixture.fixture.id]?.points === 3 ? 'text-success' : ''}>
                      You scored <b>{inputsData[fixture.fixture.id]?.points || '0'}</b> {inputsData[fixture.fixture.id]?.points === 1 ? 'point' : 'points'} for this game
                    </p>
                  )}
                </div>
              </div>
            ))}
            <div className='text-center' style={{ paddingBottom: '10px' }}>
              <button onClick={handleSubmit} className='btn btn-purple text-center'>Submit Picks</button>
              {confetti && <Confetti />}
            </div>
            <div className='container text-center padtop' style={{ paddingBottom: '10px' }}>
              <p>Total points: <b>{totalPoints}</b></p>
            </div>
            {sent && (
              <>
                <div className='alert alert-success Nav-HeaderV8 text-center'>
                  Your picks have been sent!
                </div>
                {confetti && <Confetti gravity={-5.5} />}
              </>
            )}
            {error && <p className='text-danger'>Error submitting picks!</p>}
          </div>
  
          {/* Standings Card for small screens */}
          <div className='col-12 d-lg-none mb-3' style={{ marginTop: '15px' }}>
            <div className='card'>
              <div className='card-body'>
                <h5 className='card-title'><LaLigaStandingsPicks /></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default LaLigaPicks;
