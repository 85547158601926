import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


function Social() {
  const [isPremierLeagueLoaded, setPremierLeagueLoaded] = useState(false);
  const [isBBCSportLoaded, setBBCSportLoaded] = useState(false);
  const [isSkySportsLoaded, setSkySportsLoaded] = useState(false);
  const [isFabrizioRomanoLoaded, setFabrizioRomanoLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const loadTwitterWidgetsScript = () => {
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      script.async = true;
      script.charset = 'utf-8';
      script.onload = initializeTwitterWidgets;
      document.body.appendChild(script);
    };

    const initializeTwitterWidgets = () => {
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    };

    loadTwitterWidgetsScript();
  }, []);

  const observeTwitterWidgetLoad = (containerSelector, setLoaded) => {
    const interval = setInterval(() => {
      const container = document.querySelector(containerSelector);
      if (container && container.querySelector('iframe')) {
        setLoaded(true);
        clearInterval(interval);
      }
    }, 100);
  };

  useEffect(() => {
    observeTwitterWidgetLoad('.premier-league-container', setPremierLeagueLoaded);
    observeTwitterWidgetLoad('.bbc-sport-container', setBBCSportLoaded);
    observeTwitterWidgetLoad('.sky-sports-container', setSkySportsLoaded);
    observeTwitterWidgetLoad('.fabrizio-romano-container', setFabrizioRomanoLoaded);
  }, []);

  return (
    <div>
      <Navbar />
      <div className='App-headerV3'>
      {/* <nav className="navbar navbar-light bg-light" style={{ maxWidth: '100%' }}>
  <div className="container-fluid text-center mx-auto">
    <p className="navbar-brand text-center" href="#">Ads could possibly be displayed here 1</p>
  </div>
</nav> */}

        <div className="container">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7">
                {/* <h3 className="fs-6 mb-2 text-secondary text-center text-uppercase">Welcome</h3> */}
                <h2 className="display-5 mb-5 text-center">{t('social_hub')}</h2>
                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
              </div>
            </div>
          </div>
          <section className="py-5 py-xl-8">
            <div className="container overflow-hidden">
              <div className="row gy-4 gy-xl-0 justify-content-center">
                <div className="col-12 col-md-10 col-xl-6">
                  <h3 className="card-title text-center" style={{ color: 'black' }}>Premier League {t('latest_news')}</h3>
                  <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                  <div className="card border-0 bg-transparent premier-league-container">
                    <div className="card-body text-center p-4 p-xxl-5">
                      {!isPremierLeagueLoaded && (
                        <Spinner animation="border" variant="primary" role="status">
                          <span className="visually-hidden text-light">Loading...</span>
                        </Spinner>
                      )}
                      <a className="twitter-timeline" data-height="650" data-width="100%" href="https://twitter.com/premierleague?ref_src=twsrc%5Etfw"></a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-10 col-xl-6">
                  <h3 className="card-title text-center" style={{ color: 'black' }}>BBC Sport {t('latest_news')}</h3>
                  <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                  <div className="card border-0 bg-transparent bbc-sport-container">
                    <div className="card-body text-center p-4 p-xxl-5">
                      {!isBBCSportLoaded && (
                        <Spinner animation="border" variant="primary" role="status">
                          <span className="visually-hidden text-light">Loading...</span>
                        </Spinner>
                      )}
                      <a className="twitter-timeline" data-height="650" data-width="100%" href="https://twitter.com/BBCSport?ref_src=twsrc%5Etfw"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5 py-xl-8">
            <div className="container overflow-hidden">
              <div className="row gy-4 gy-xl-0 justify-content-center">
                <div className="col-12 col-md-10 col-xl-6">
                  <h3 className="card-title text-center" style={{ color: 'black' }}>Sky Sports {t('latest_news')}</h3>
                  <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                  <div className="card border-0 bg-transparent sky-sports-container">
                    <div className="card-body text-center p-4 p-xxl-5">
                      {!isSkySportsLoaded && (
                        <Spinner animation="border" variant="primary" role="status">
                          <span className="visually-hidden text-light">Loading...</span>
                        </Spinner>
                      )}
                      <a className="twitter-timeline" data-height="650" data-width="100%" href="https://twitter.com/SkySportsNews?ref_src=twsrc%5Etfw"></a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-10 col-xl-6">
                  <h3 className="card-title text-center" style={{ color: 'black' }}>Fabrizio Romano {t('latest_news')}</h3>
                  <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                  <div className="card border-0 bg-transparent fabrizio-romano-container">
                    <div className="card-body text-center p-4 p-xxl-5">
                      {!isFabrizioRomanoLoaded && (
                        <Spinner animation="border" variant="primary" role="status">
                          <span className="visually-hidden text-light">Loading...</span>
                        </Spinner>
                      )}
                      <a className="twitter-timeline" data-height="650" data-width="100%" href="https://twitter.com/FabrizioRomano?ref_src=twsrc%5Etfw"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Social;
