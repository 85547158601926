import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ChampionshipStandingsPicks() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};

    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
      standingsMap[fixture.teams.away.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
    });

    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }

      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;

      // Determine match result
      let homePoints = 0;
      let awayPoints = 0;
      let homeResult = "";
      let awayResult = "";

      if (homeGoals > awayGoals) {
        homePoints = 3;
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        awayPoints = 3;
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homePoints = 1;
        awayPoints = 1;
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }

      // Update standings
      standingsMap[homeTeam].points += homePoints;
      standingsMap[awayTeam].points += awayPoints;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
    });

    // Convert standings map to array
    const standingsArray = Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order

    return standingsArray;
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  return (
    <div className=''>
      <div className=''>
  



<div className='container'>
    <h5>Standings</h5>
    <hr className='w-25' />
</div>
        <div className='container'>
          {loading ? (
            <h2 className='text-center text-dark'><Spinner /></h2>
          ) : (
            <table className="table table table-striped" style={{ backgroundColor: '' ,  }}>
              <thead className='' style={{ backgroundColor: '', color: 'grey' , fontFamily: 'normal' }}>
                <tr>
                <th
  scope="col"
  style={{ backgroundColor: '', color: '', fontFamily: 'Arial, sans-serif', fontStyle: 'normal' }}
>
  #
</th>

{/* Arial
text-normal: Normal font weight (font-weight: normal;).
text-bold: Bold font weight (font-weight: bold;).
text-light: Light font weight (font-weight: 300;).
text-semibold: Semi-bold font weight (font-weight: 600;).
text-extrabold: Extra-bold font weight (font-weight: 800;). */}
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'bold' }}>Team</th>
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>P</th>
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>W</th>
                  <th scope="col" style={{ backgroundColor: '', color: ''  , fontFamily: 'normal'}}>D</th>
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>L</th>
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>Pts</th>
                </tr>
              </thead>
              <tbody >
                {standings.map((team, index) => (
                  <tr key={index}>
                    <th  scope="row" className={index < 2 ? 'text-primary' : index >= standings.length - 3 ? 'text-danger' : index == 2 ? 'text-warning' :  index == 3 ? 'text-warning' : index == 4 ? 'text-warning' : index == 5 ? 'text-warning' :  ''} style={{ backgroundColor: '', color: 'black' }}>{index + 1}</th>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>
                      {team.team === 'Manchester United' ? (
                        <Link to="/manunited" style={{ textDecoration: 'none', color: 'black' }}>
                          Man Utd
                        </Link>
                      ) : team.team === 'Blackburn' ? (
                        <Link to="/Blackburn" style={{ textDecoration: 'none', color: 'black' }}>Blackburn</Link>
                      ) : team.team === 'Bristol City' ? (
                        <Link to="/bristol" style={{ textDecoration: 'none', color: 'black' }}>Bristol</Link>
                      ) : team.team === 'Burnley' ? (
                        <Link to="/Burnley" style={{ textDecoration: 'none', color: 'black' }}>Burnley</Link>
                      ) : team.team === 'Cardiff' ? (
                        <Link to="/cardiff" style={{ textDecoration: 'none', color: 'black' }}>Cardiff</Link>
                      ) : team.team === 'Coventry' ? (
                        <Link to="/coventry" style={{ textDecoration: 'none', color: 'black' }}>Coventry</Link>
                      ) : team.team === 'Derby' ? (
                        <Link to="/Derby" style={{ textDecoration: 'none', color: 'black' }}>Derby </Link>
                      ) : team.team === 'Hull City' ? (
                        <Link to="/hull" style={{ textDecoration: 'none', color: 'black' }}>Hull</Link>
                      ) : team.team === 'Leeds' ? (
                        <Link to="/leeds" style={{ textDecoration: 'none', color: 'black' }}>Leeds</Link>
                      ) : team.team === 'Luton' ? (
                        <Link to="/luton" style={{ textDecoration: 'none', color: 'black' }}>Luton</Link>
                      ) : team.team === 'Middlesbrough' ? (
                        <Link to="/middlesbrough" style={{ textDecoration: 'none', color: 'black' }}>Middlesbrough</Link>
                      ) : team.team === 'Millwall' ? (
                        <Link to="/millwall" style={{ textDecoration: 'none', color: 'black' }}>Millwall</Link>
                      ) : team.team === 'Norwich' ? (
                        <Link to="/Norwich" style={{ textDecoration: 'none', color: 'black' }}>Norwich</Link>
                      ) : team.team === 'Oxford United' ? (
                        <Link to="/Oxford " style={{ textDecoration: 'none', color: 'black' }}>Oxford </Link>
                      ) : team.team === 'Plymouth' ? (
                        <Link to="/Plymouth" style={{ textDecoration: 'none', color: 'black' }}>Plymouth</Link>
                      ) : team.team === 'Portsmouth' ? (
                        <Link to="/Portsmouth" style={{ textDecoration: 'none', color: 'black' }}>Portsmouth</Link>
                      ) : team.team === 'Preston' ? (
                        <Link to="/preston" style={{ textDecoration: 'none', color: 'black' }}>Preston</Link>
                      ) : team.team === 'QPR' ? (
                        <Link to="/QPR" style={{ textDecoration: 'none', color: 'black' }}>QPR</Link>
                      ) : team.team === 'Sunderland' ? (
                        <Link to="/Sunderland" style={{ textDecoration: 'none', color: 'black' }}>Sunderland</Link>
                      ) : team.team === 'Sheffield Wednesday' ? (
                        <Link to="/sheffieldwed" style={{ textDecoration: 'none', color: 'black' }}>Sheffield Wed</Link>
                      ) : team.team === 'Stoke City' ? (
                        <Link to="/Stoke" style={{ textDecoration: 'none', color: 'black' }}>Stoke</Link>
                      ) : team.team === 'Swansea' ? (
                        <Link to="/Swansea" style={{ textDecoration: 'none', color: 'black' }}>Swansea</Link>
                      ) : team.team === 'Watford' ? (
                        <Link to="/Watford" style={{ textDecoration: 'none', color: 'black' }}>Watford</Link>
                      ) :  team.team === 'West Brom' ? (
                        <Link to="/WestBrom" style={{ textDecoration: 'none', color: 'black' }}>West Brom</Link>
                      ) :  team.team === 'Sheffield Utd' ? (
                        <Link to="/SheffieldUtd" style={{ textDecoration: 'none', color: 'black' }}>Sheffield Utd</Link>
                     ) : (
                        team.team
                      )}
                    </td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.matchesPlayed}</td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.wins}</td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.draws}</td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.losses}</td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
       <div className='d-flex justify-content-center'>
            <div className='text-center text-primary'>| </div>
            <div className='text-dark'style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>Promotion</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-warning'>| </div>
            <div className='text-dark'style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>Play Off</div>
          </div>
        
          <div className='d-flex justify-content-center'>
            <div className='text-center text-danger'>| </div>
            <div className='text-dark'style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>Relegation</div>
          </div>
        </div>
       
      </div>

    </div>
  );
}

export default ChampionshipStandingsPicks;
