


import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Alaves from '../Team-Kits-Images/Alaves.png'
import Athleti from '../Team-Kits-Images/Athleti.png'
import Athletic from '../Team-Kits-Images/Athletic.png'
import Barcelona from '../Team-Kits-Images/Barcelona.png'
import Betis from '../Team-Kits-Images/Betis.png'
import CeltaVigo from '../Team-Kits-Images/CeltaVigo.png'
import Espanyol from '../Team-Kits-Images/Espanyol.png'
import Getafe from '../Team-Kits-Images/Getafe.png'
import Girona from '../Team-Kits-Images/Girona.png'
import LasPalmas from '../Team-Kits-Images/LasPalmas.png'
import Leganes from '../Team-Kits-Images/Leganes.png'
import Madrid from '../Team-Kits-Images/Madrid.png'
import Mallorca from '../Team-Kits-Images/Mallorca.png'
import Osasuna from '../Team-Kits-Images/Osasuna.png'
import Rayo from '../Team-Kits-Images/Rayo.png'
import Sevilla from '../Team-Kits-Images/Sevilla.png'
import Sociedad from '../Team-Kits-Images/Sociedad.png'
import Valencia from '../Team-Kits-Images/Valencia.png'
import Valladolid from '../Team-Kits-Images/Valladolid.png'
import Villareal from '../Team-Kits-Images/Villareal.png'
import { useTranslation } from 'react-i18next';



function LaLigaFixtures() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const { t } = useTranslation();


    useEffect(() => {
        const fetchFixturesAndPicks = async () => {
            try {
                // Fetch fixtures data from the API
                const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/140/fixtures/season/2024", {
                    headers: {
                        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                    }
                });
                const fixturesData = await fixturesResponse.json();
                console.log("Fetched fixtures data:", fixturesData);
    
                // Fetch user's picks
                if (userId) {
                    const picksRef = ref(getDatabase(), `picks/${userId}`);
                    const snapshot = await get(picksRef);
                    const picksData = snapshot.val();
    
                    if (picksData) {
                        setInputsData(picksData);
                    }
                }
    
                // Find the current round
                const now = new Date();
                const currentRound = rounds.find(round => now >= round.startDate && now <= round.endDate);
                console.log("Current Round:", currentRound); // Log the current round to verify
    
                // Filter fixtures for the current round
                const fixturesForCurrentRound = fixturesData.filter(fixture => {
                    const fixtureDate = new Date(fixture.fixture.date);
                    return fixtureDate >= currentRound.startDate && fixtureDate <= currentRound.endDate;
                });
    
                // Sort the fixtures for the current round by date
                fixturesForCurrentRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
                // Set the fixtures state with the fixtures for the current round
                setFixtures(fixturesForCurrentRound);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { homeTeam, awayTeam }
        }));
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2024-06-11'), endDate: new Date('2024-08-20') },
        { number: 2, startDate: new Date('2024-08-20'), endDate: new Date('2024-08-26') },
        { number: 3, startDate: new Date('2024-08-26'), endDate: new Date('2024-09-02') },
        { number: 4, startDate: new Date('2024-09-02'), endDate: new Date('2024-09-16') },
        { number: 5, startDate: new Date('2024-09-16'), endDate: new Date('2024-09-23') },
        { number: 6, startDate: new Date('2024-09-23'), endDate: new Date('2024-09-31') },
        { number: 7, startDate: new Date('2024-09-31'), endDate: new Date('2024-10-07') },
        { number: 8, startDate: new Date('2024-10-07'), endDate: new Date('2024-10-22') },
        { number: 9, startDate: new Date('2024-10-22'), endDate: new Date('2024-10-28') },
        { number: 10, startDate: new Date('2024-10-28'), endDate: new Date('2024-11-03') },
        { number: 11, startDate: new Date('2024-11-03'), endDate: new Date('2024-11-10') }, 
        { number: 12, startDate: new Date('2024-11-10'), endDate: new Date('2024-11-24') },
        { number: 13, startDate: new Date('2024-11-24'), endDate: new Date('2024-12-01') },
        { number: 14, startDate: new Date('2024-12-01'), endDate: new Date('2024-12-05') },
        { number: 15, startDate: new Date('2024-12-05'), endDate: new Date('2024-12-08') },
        { number: 16, startDate: new Date('2024-12-08'), endDate: new Date('2024-12-15') },
        { number: 17, startDate: new Date('2024-12-15'), endDate: new Date('2024-12-22') },
        { number: 18, startDate: new Date('2024-12-22'), endDate: new Date('2024-12-27') },
        { number: 19, startDate: new Date('2024-12-27'), endDate: new Date('2024-12-30') },
        { number: 20, startDate: new Date('2025-01-01'), endDate: new Date('2025-01-06') },
        { number: 21, startDate: new Date('2025-01-06'), endDate: new Date('2025-01-16') },
        { number: 22, startDate: new Date('2025-01-16'), endDate: new Date('2025-01-19') },
        { number: 23, startDate: new Date('2025-01-19'), endDate: new Date('2025-01-26') },
        { number: 24, startDate: new Date('2025-01-26'), endDate: new Date('2025-02-02') },
        { number: 25, startDate: new Date('2025-02-02'), endDate: new Date('2025-02-16') },
        { number: 26, startDate: new Date('2025-02-16'), endDate: new Date('2025-02-23') },
        { number: 27, startDate: new Date('2025-02-23'), endDate: new Date('2025-02-27') },
        { number: 28, startDate: new Date('2025-02-27'), endDate: new Date('2025-03-09') },
        { number: 29, startDate: new Date('2025-03-09'), endDate: new Date('2025-03-16') },
        { number: 30, startDate: new Date('2025-03-16'), endDate: new Date('2025-04-03') },
        { number: 31, startDate: new Date('2025-04-03'), endDate: new Date('2025-04-06') },
        { number: 32, startDate: new Date('2025-04-06'), endDate: new Date('2025-04-13') },
        { number: 33, startDate: new Date('2025-04-13'), endDate: new Date('2025-04-20') },
        { number: 34, startDate: new Date('2025-04-20'), endDate: new Date('2025-04-27') },
        { number: 35, startDate: new Date('2025-04-27'), endDate: new Date('2025-05-04') },
        { number: 36, startDate: new Date('2025-05-04'), endDate: new Date('2025-05-11') },
        { number: 37, startDate: new Date('2025-05-11'), endDate: new Date('2025-05-19') },
        { number: 38, startDate: new Date('2025-05-19'), endDate: new Date('2025-07-20') },
    ];
    

    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/140/fixtures/season/2024?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    

    
    
    return (
        <div className='App-headerV3'>
            <Navbar />
            <div className='App-headerV3'>
            <h2 className="text-dark text-center padtop mb-3">La Liga Fixtures</h2>
            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown-center text-center padbot">
                    <button className="btn btn-custom dropdown-toggle btn mb-2 text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu text-center custom" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className="container mb-3 " style={{marginBottom: '15px'}}>
                    <div className='mb-2 '>
                       
                            <div className='mb-2'>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3 col-lg-12 mx-auto card' style={{ backgroundColor: '#fff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row ">
                                        <div className="col text-center "> 
                                        {/* Display kit of home team */}
                                        {fixture.teams.home.name === 'Getafe' ? (
    <div className='Getafe'>
        <img src={Getafe} alt="Getafe" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.home.name === 'Athletic Club' ? (
    <img src={Athletic} alt="Athletic Club" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Real Betis' ? (
    <img src={Betis} alt="Real Betis" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Girona' ? (
    <img src={Girona} alt="Girona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Mallorca' ? (
    <img src={Mallorca} alt="Mallorca" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Real Madrid' ? (
    <img src={Madrid} alt="Real Madrid" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Las Palmas' ? (
    <img src={LasPalmas} alt="Las Palmas" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Sevilla' ? (
    <img src={Sevilla} alt="Sevilla" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Villarreal' ? (
    <img src={Villareal} alt="Villarreal" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Atletico Madrid' ? (
    <img src={Athleti} alt="Atletico Madrid" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Valencia' ? (
    <img src={Valencia} alt="Valencia" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Barcelona' ? (
    <img src={Barcelona} alt="Barcelona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Real Sociedad' ? (
    <img src={Sociedad} alt="Real Sociedad" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Rayo Vallecano' ? (
    <img src={Rayo} alt="Rayo Vallecano" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Celta Vigo' ? (
    <img src={CeltaVigo} alt="Celta Vigo" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Alaves' ? (
    <img src={Alaves} alt="Alaves" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Osasuna' ? (
    <img src={Osasuna} alt="Osasuna" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Leganes' ? (
    <img src={Leganes} alt="Leganes" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Espanyol' ? (
    <img src={Espanyol} alt="Espanyol" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Valladolid' ? (
    <img src={Valladolid} alt="Valladolid" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}
                                        {/* Display kit of home team */}
                                        </div>


                                        <>
  <div className="col padtop1 ">
    <input
      className="form-control text-center fs-3 "
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
      disabled
    />
  </div>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
      disabled
    />
  </div>
</>



                                            <div className="col text-center"> 
                                         {/* Display kit of away team */} 
                                         {' '}
                                         {fixture.teams.away.name === 'Getafe' ? (
    <div className='Getafe'>
        <img src={Getafe} alt="Getafe" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.away.name === 'Athletic Club' ? (
    <img src={Athletic} alt="Athletic Club" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Real Betis' ? (
    <img src={Betis} alt="Real Betis" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Girona' ? (
    <img src={Girona} alt="Girona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Mallorca' ? (
    <img src={Mallorca} alt="Mallorca" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Real Madrid' ? (
    <img src={Madrid} alt="Real Madrid" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Las Palmas' ? (
    <img src={LasPalmas} alt="Las Palmas" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Sevilla' ? (
    <img src={Sevilla} alt="Sevilla" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Villarreal' ? (
    <img src={Villareal} alt="Villarreal" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Atletico Madrid' ? (
    <img src={Athleti} alt="Atletico Madrid" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Valencia' ? (
    <img src={Valencia} alt="Valencia" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Barcelona' ? (
    <img src={Barcelona} alt="Barcelona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Real Sociedad' ? (
    <img src={Sociedad} alt="Real Sociedad" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Rayo Vallecano' ? (
    <img src={Rayo} alt="Rayo Vallecano" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Celta Vigo' ? (
    <img src={CeltaVigo} alt="Celta Vigo" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Alaves' ? (
    <img src={Alaves} alt="Alaves" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Osasuna' ? (
    <img src={Osasuna} alt="Osasuna" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Leganes' ? (
    <img src={Leganes} alt="Leganes" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Espanyol' ? (
    <img src={Espanyol} alt="Espanyol" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Valladolid' ? (
    <img src={Valladolid} alt="Valladolid" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}
                                        {/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                 
                                                                                                                {/* Display home team Initials */}

                                                                                                                {fixture.teams.home.name === 'Getafe' ? (
    <h5>GET</h5>
) : fixture.teams.home.name === 'Athletic Club' ? (
    <h5>ATH</h5>
) : fixture.teams.home.name === 'Real Betis' ? (
    <h5>BET</h5>
) : fixture.teams.home.name === 'Girona' ? (
    <h5>GIR</h5>
) : fixture.teams.home.name === 'Mallorca' ? (
    <h5>MAL</h5>
) : fixture.teams.home.name === 'Real Madrid' ? (
    <h5>RM</h5>
) : fixture.teams.home.name === 'Las Palmas' ? (
    <h5>LAS</h5>
) : fixture.teams.home.name === 'Sevilla' ? (
    <h5>SEV</h5>
) : fixture.teams.home.name === 'Villarreal' ? (
    <h5>VIL</h5>
) : fixture.teams.home.name === 'Atletico Madrid' ? (
    <h5>ATM</h5>
) : fixture.teams.home.name === 'Valencia' ? (
    <h5>VAL</h5>
) : fixture.teams.home.name === 'Barcelona' ? (
    <h5>BAR</h5>
) : fixture.teams.home.name === 'Real Sociedad' ? (
    <h5>SOC</h5>
) : fixture.teams.home.name === 'Rayo Vallecano' ? (
    <h5>RAY</h5>
) : fixture.teams.home.name === 'Celta Vigo' ? (
    <h5>CEL</h5>
) : fixture.teams.home.name === 'Alaves' ? (
    <h5>ALA</h5>
) : fixture.teams.home.name === 'Osasuna' ? (
    <h5>OSA</h5>
) : fixture.teams.home.name === 'Leganes' ? (
    <h5>LEG</h5>
) : fixture.teams.home.name === 'Espanyol' ? (
    <h5>ESP</h5>
) : fixture.teams.home.name === 'Valladolid' ? (
    <h5>VAL</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}

                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display home team Initials */}

                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                    {' '}
                                                    {fixture.teams.away.name === 'Getafe' ? (
    <h5>GET</h5>
) : fixture.teams.away.name === 'Athletic Club' ? (
    <h5>ATH</h5>
) : fixture.teams.away.name === 'Real Betis' ? (
    <h5>BET</h5>
) : fixture.teams.away.name === 'Girona' ? (
    <h5>GIR</h5>
) : fixture.teams.away.name === 'Mallorca' ? (
    <h5>MAL</h5>
) : fixture.teams.away.name === 'Real Madrid' ? (
    <h5>RM</h5>
) : fixture.teams.away.name === 'Las Palmas' ? (
    <h5>LAS</h5>
) : fixture.teams.away.name === 'Sevilla' ? (
    <h5>SEV</h5>
) : fixture.teams.away.name === 'Villarreal' ? (
    <h5>VIL</h5>
) : fixture.teams.away.name === 'Atletico Madrid' ? (
    <h5>ATM</h5>
) : fixture.teams.away.name === 'Valencia' ? (
    <h5>VAL</h5>
) : fixture.teams.away.name === 'Barcelona' ? (
    <h5>BAR</h5>
) : fixture.teams.away.name === 'Real Sociedad' ? (
    <h5>SOC</h5>
) : fixture.teams.away.name === 'Rayo Vallecano' ? (
    <h5>RAY</h5>
) : fixture.teams.away.name === 'Celta Vigo' ? (
    <h5>CEL</h5>
) : fixture.teams.away.name === 'Alaves' ? (
    <h5>ALA</h5>
) : fixture.teams.away.name === 'Osasuna' ? (
    <h5>OSA</h5>
) : fixture.teams.away.name === 'Leganes' ? (
    <h5>LEG</h5>
) : fixture.teams.away.name === 'Espanyol' ? (
    <h5>ESP</h5>
) : fixture.teams.away.name === 'Valladolid' ? (
    <h5>VAL</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center mb-2">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                    </div>
                                ))}
                                                                    <div></div>

                            </div>
                    </div>
                </div>
            </div>
{/* <div className='mb-2 padtop'></div> */}
            <Footer />
        </div>
    );
}

export default LaLigaFixtures;
