
import Aberdeen from '../Team-Kits-Images/Aberdeen.png'
import Celtic from '../Team-Kits-Images/Celtic.png'
import Dundee from '../Team-Kits-Images/Dundee.png'
import DundeeUtd from '../Team-Kits-Images/DundeeUtd.png'
import Hearts from '../Team-Kits-Images/Hearts.png'
import Hibernian from '../Team-Kits-Images/Hibernian.png'
import Kilmarnock from '../Team-Kits-Images/Kilmarnock.png'
import Motherwell from '../Team-Kits-Images/Motherwell.png'
import Rangers from '../Team-Kits-Images/Rangers.png'
import RossCounty from '../Team-Kits-Images/RossCounty.png'
import StJohnstone from '../Team-Kits-Images/StJohnstone.png'
import StMirren from '../Team-Kits-Images/StMirren.png'
import { useTranslation } from 'react-i18next';


import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Confetti from 'react-confetti';
import ScottishStandingsPicks from './ScottishStandingsPicks'
 
function ScottishPicks24() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');

  const fetchTotalPoints = async (displayName) => { 
    try {
      const db = getDatabase();
      const totalPointsRef = ref(db, `Scottish2024TotalPoints/${displayName}`);
      const totalPointsSnapshot = await get(totalPointsRef);
      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);
      console.log("Fetched total points:", totalPointsData.totalPoints);
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  const fetchPicks = async (displayName) => {
    try {
      const db = getDatabase();
      const picksRef = ref(db, `Scottish2024Picks/${displayName}`);
      const totalPointsRef = ref(db, `Scottish2024TotalPoints/${displayName}`);

      const [picksSnapshot, totalPointsSnapshot] = await Promise.all([
        get(picksRef),
        get(totalPointsRef)
      ]);

      const picksData = picksSnapshot.val() || {};
      const userPicks = picksData || {};
      setInputsData(userPicks);
      setDisplayName(displayName);

      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);

      const pointsData = {};
      Object.keys(userPicks).forEach(fixtureId => {
        pointsData[fixtureId] = userPicks[fixtureId].points;
      });
      setFixturePoints(pointsData);

      Object.keys(userPicks).forEach(fixtureId => {
        if (!intervalRefs.current[fixtureId]) {
          startPointsIncrement(fixtureId);
        }
      });
      console.log("Fetched picks:", userPicks);
    } catch (error) {
      console.error("Error fetching picks:", error);
    }
  };

  const [dateRanges] = useState([
    { start: '2024-07-03', end: '2024-08-06' },  // Matchweek 1
    { start: '2024-08-06', end: '2024-08-12' },  // Matchweek 2
    { start: '2024-08-12', end: '2024-08-26' },  // Matchweek 3
    { start: '2024-08-26', end: '2024-09-02' },  // Matchweek 4 // 
    { start: '2024-09-02', end: '2024-09-16' },  // Matchweek 5
    { start: '2024-09-16', end: '2024-09-22' },  // Matchweek 6
    { start: '2024-09-22', end: '2024-09-29' },  // Matchweek 7
    { start: '2024-09-29', end: '2024-10-06' },  // Matchweek 8
    { start: '2024-10-06', end: '2024-10-20' },  // Matchweek 9
    { start: '2024-10-20', end: '2024-10-27' },  // Matchweek 10
    { start: '2024-10-27', end: '2024-10-31' },  // Matchweek 11
    { start: '2024-10-31', end: '2024-11-03' },  // Matchweek 12 // 
    { start: '2024-11-03', end: '2024-11-10' },  // Matchweek 13
    { start: '2024-11-10', end: '2024-11-24' },  // Matchweek 14
    { start: '2024-11-24', end: '2024-12-01' },  // Matchweek 15
    { start: '2024-12-01', end: '2024-12-05' },  // Matchweek 16
    { start: '2024-12-05', end: '2024-12-08' },  // Matchweek 17
    { start: '2024-12-08', end: '2024-12-15' },  // Matchweek 18
    { start: '2024-12-15', end: '2024-12-22' },  // Matchweek 19
    { start: '2024-12-22', end: '2024-12-27' },  // Matchweek 20
    { start: '2024-12-27', end: '2025-12-30' },  // Matchweek 21
    { start: '2025-12-31', end: '2025-01-03' },  // Matchweek 22
    { start: '2025-01-03', end: '2025-01-06' },  // Matchweek 23
    { start: '2025-01-06', end: '2025-01-12' },  // Matchweek 24
    { start: '2025-01-12', end: '2025-01-26' },  // Matchweek 25
    { start: '2025-01-26', end: '2025-02-02' },  // Matchweek 26
    { start: '2025-02-02', end: '2025-02-16' },  // Matchweek 27
    { start: '2025-02-16', end: '2025-02-23' },  // Matchweek 28
    { start: '2025-02-23', end: '2025-02-27' },  // Matchweek 29
    { start: '2025-02-27', end: '2025-03-02' },  // Matchweek 30
    { start: '2025-03-02', end: '2025-03-16' },  // Matchweek 31
    { start: '2025-03-16', end: '2025-04-30' },  // Matchweek 32
    { start: '2025-04-30', end: '2025-04-13' },  // Matchweek 33
    // Add more matchweeks as needed
]);


useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);


  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  

  useEffect(() => {
    if (user) {
      const displayName = user.displayName || 'Anonymous';
      fetchPicks(displayName);
      fetchTotalPoints(displayName);
    }
  }, [user]);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/179/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
        console.log("Fetched fixtures:", fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };
  
    fetchFixtures();
  }, []);

  const handleInputChange = (fixtureId, team, value) => {
    setInputsData(prevInputsData => ({
      ...prevInputsData,
      [fixtureId]: {
        ...prevInputsData[fixtureId],
        [team]: value,
        points: prevInputsData[fixtureId]?.points || 0,
        pointsAwarded: prevInputsData[fixtureId]?.pointsAwarded || false
      }
    }));
  };

// Function to award points for a specific user's fixture
const awardPointsForUser = async (userDisplayName, fixtureId) => {
  const fixture = fixtures.find(f => f.fixture.id === parseInt(fixtureId));
  const userPrediction = inputsData[fixtureId];

  if (fixture && userPrediction && (fixture.fixture.status.short === 'FT' || fixture.fixture.status.short === 'AET') && !userPrediction.pointsAwarded) {
    const actualHomeScore = fixture.goals.home;
    const actualAwayScore = fixture.goals.away;
    const predictedHomeScore = parseInt(userPrediction.home, 10);
    const predictedAwayScore = parseInt(userPrediction.away, 10);

    console.log(`Checking fixture ${fixtureId}: actual (${actualHomeScore}, ${actualAwayScore}), predicted (${predictedHomeScore}, ${predictedAwayScore})`);

    let pointsToAward = 0;

    // Exact match
    if (predictedHomeScore === actualHomeScore && predictedAwayScore === actualAwayScore) {
      pointsToAward = 3;
    } else {
      // Correct outcome
      const actualOutcome = actualHomeScore > actualAwayScore ? 'home' : actualHomeScore < actualAwayScore ? 'away' : 'draw';
      const predictedOutcome = predictedHomeScore > predictedAwayScore ? 'home' : predictedHomeScore < predictedAwayScore ? 'away' : 'draw';

      if (actualOutcome === predictedOutcome) {
        pointsToAward = 1;
      }
    }

    if (pointsToAward > 0) {
      await updatePoints(userDisplayName, fixtureId, pointsToAward);
    }
  }
};

// Function to update points for a specific user
const updatePoints = async (displayName, fixtureId, points) => {
  const db = getDatabase();
  const userRef = ref(db, `Scottish2024Picks/${displayName}/${fixtureId}`);
  const totalPointsRef = ref(db, `Scottish2024TotalPoints/${displayName}`);

  try {
    const currentFixtureData = (await get(userRef)).val() || {};
    if (!currentFixtureData.pointsAwarded) {
      await set(userRef, {
        ...currentFixtureData,
        points: (currentFixtureData.points || 0) + points,
        pointsAwarded: true
      });

      const totalPointsSnapshot = await get(totalPointsRef);
      const currentTotalPoints = totalPointsSnapshot.val() || { totalPoints: 0 };

      const newTotalPoints = currentTotalPoints.totalPoints + points;

      await set(totalPointsRef, { totalPoints: newTotalPoints });

      setTotalPoints(newTotalPoints);
      console.log(`Updated points for ${displayName}:`, { fixtureId, points: (currentFixtureData.points || 0) + points });
    }
  } catch (error) {
    console.error('Error updating points:', error);
  }
};

// Fetch picks and award points for all users
const awardPointsForAllUsers = async (fixtureId) => {
  try {
    const db = getDatabase();
    const picksRef = ref(db, `Scottish2024Picks`);

    const picksSnapshot = await get(picksRef);
    const picksData = picksSnapshot.val() || {};

    for (const userDisplayName in picksData) {
      if (picksData.hasOwnProperty(userDisplayName)) {
        const userPicks = picksData[userDisplayName];
        if (userPicks[fixtureId]) {
          await awardPointsForUser(userDisplayName, fixtureId);
        }
      }
    }
  } catch (error) {
    console.error('Error awarding points for all users:', error);
  }
};

useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);


const startPointsIncrement = (fixtureId) => {
  if (intervalRefs.current[fixtureId]) {
    clearInterval(intervalRefs.current[fixtureId]);
  }

  intervalRefs.current[fixtureId] = setInterval(() => {
    awardPointsForAllUsers(fixtureId);
  }, 60000);
};


  const handleSubmit = async () => {
    if (userId) {
      const db = getDatabase();
      const picksRef = ref(db, `Scottish2024Picks/${displayName}`);
      const totalPointsRef = ref(db, `Scottish2024TotalPoints/${displayName}`);

      // Extract displayName and default to 'Anonymous' if not available
      let userDisplayName = user.displayName || 'Anonymous';

      // Use regex to extract the first item in the parentheses
      const match = userDisplayName.match(/\(([^)]+)\)/);
      if (match && match[1]) {
        userDisplayName = match[1];
      }

      try {
        // Fetch existing picks from the backend
        const picksSnapshot = await get(picksRef);
        const existingPicksData = picksSnapshot.val() || {};

        // Merge new picks with existing picks
        const mergedPicks = {
          ...existingPicksData,
          ...inputsData
        };

        const dataToSubmit = mergedPicks;

        console.log("Submitting data:", dataToSubmit);
        await set(picksRef, dataToSubmit);

        // Check if totalPoints entry exists, if not, initialize it
        const totalPointsSnapshot = await get(totalPointsRef);
        if (!totalPointsSnapshot.exists()) {
          await set(totalPointsRef, { totalPoints: 0 });
        }

        setSent(true);
        setTimeout(() => setSent(false), 15000);
        setConfetti(true);
        setTimeout(() => setConfetti(false), 5000);

        Object.keys(inputsData).forEach(fixtureId => {
          startPointsIncrement(fixtureId);
        });
      } catch (error) {
        console.error('Error submitting picks:', error);
        setError(true);
        setTimeout(() => setError(false), 5000);
      }
    }
  };
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  
  const totalPointsa = useMemo(() => {
    return Object.values(inputsData).reduce((acc, input) => {
      return acc + (input.points || 0);
    }, 0);
  }, [inputsData]);

  const teamInitials = {
    'Celtic': 'CEL',
    'Heart Of Midlothian': 'HEA',
    'Motherwell': 'MOT',
    'Dundee Utd': 'DUU',
    'ST Mirren': 'STM',
    'Kilmarnock': 'KIL',
    'Hibernian': 'HIB',
    'Dundee': 'DUN',
    'Ross County': 'ROS',
    'Rangers': 'RAN',
    'Aberdeen': 'ABE',
    'ST Johnstone': 'STJ',
  };
  

  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  const teamLogos = {
    'Aberdeen': Aberdeen,
    'Celtic': Celtic,
    'Heart Of Midlothian': Hearts,
    'Motherwell': Motherwell,
    'Dundee': Dundee,
    'Kilmarnock': Kilmarnock,
    'Rangers': Rangers,
    'Hibernian': Hibernian,
    'ST Johnstone': StJohnstone,
    'Ross County': RossCounty,
    'ST Mirren': StMirren,
    'Dundee Utd': DundeeUtd,
};

const renderTeamLogo = (teamName) => {
const logo = teamLogos[teamName];
if (logo) {
  return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
}
return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
};
  

  return (
    <div>
    <Navbar />
    <div className='App-headerV3'>
      <h2 className='text-dark text-center padtop'>SPFL Picks</h2>
      <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
        <div className="dropdown d-inline-block">
          <button
            className="btn btn-purple dropdown-toggle"
            type="button"
            id="groupStageDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedMatchday}
          </button>
          <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
            <ul className="list-group">
              {dateRanges.map((range, index) => (
                <li key={index} className="list-group-item">
              <button className="dropdown-item" onClick={() => handlePageChange(index + 1)}>
              Round {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
  
      <div className='container'>
        <div className='row'>
          {/* Standings Card for large screens */}
          <div className='col-lg-5 d-none d-lg-flex align-items-start justify-content-end standings-card-margin' style={{ paddingTop: '15px' }}>
  <div className='card'>
    <div className='card-body'>
      <h5 className='card-title'><ScottishStandingsPicks /></h5>
    </div>
  </div>
</div>

  
          {/* Main content area */}
          <div className='col-lg-7 col-12'>
            {filteredFixtures.map(fixture => (
              <div key={fixture.fixture.id} className='my-3 card'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  <div className="row align-items-center">
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                    <div className="col text-center padtopinput border-secondary">
                      <div className='form-group border-secondary text-center'>
                        <select
                          value={inputsData[fixture.fixture.id]?.home ?? ''}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10);
                            if (!isNaN(value) && value >= 0 && value <= 10) {
                              handleInputChange(fixture.fixture.id, 'home', value);
                            } else {
                              handleInputChange(fixture.fixture.id, 'home', '');
                            }
                          }}
                          className='form-control text-center border-secondary'
                          disabled={fixture.fixture.status.short !== 'NS'}
                          style={{ textAlign: 'center', textAlignLast: 'center' }} // Inline styles for centering text

                        >
                          <option value="">Pick</option>
                          {[...Array(11).keys()].map(num => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col text-center padtopinput border-secondary">
                      <div className='form-group border-secondary text-center'>
                        <select
                          value={inputsData[fixture.fixture.id]?.away ?? ''}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10);
                            if (!isNaN(value) && value >= 0 && value <= 10) {
                              handleInputChange(fixture.fixture.id, 'away', value);
                            } else {
                              handleInputChange(fixture.fixture.id, 'away', '');
                            }
                          }}
                          className='form-control text-center border-secondary'
                          disabled={fixture.fixture.status.short !== 'NS'}
                          style={{ textAlign: 'center', textAlignLast: 'center' }} // Inline styles for centering text

                        >
                          <option value="">Pick</option>
                          {[...Array(11).keys()].map(num => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
                  <div>Result: {fixture.fixture.status.short === 'NS' ? 'TBA' : `${fixture.goals.home} - ${fixture.goals.away}`}</div>
                  <p className="text-center">{convertToFormattedDateTime(fixture.fixture.date)}</p>
                  {fixture.fixture.status.short === 'FT' && (
                    <p className={inputsData[fixture.fixture.id]?.points === 1 ? 'text-warning' : inputsData[fixture.fixture.id]?.points === 3 ? 'text-success' : ''}>
                      You scored <b>{inputsData[fixture.fixture.id]?.points || '0'}</b> {inputsData[fixture.fixture.id]?.points === 1 ? 'point' : 'points'} for this game
                    </p>
                  )}
                </div>
              </div>
            ))}
  
            <div className='text-center' style={{ paddingBottom: '10px' }}>
              <button onClick={handleSubmit} className='btn btn-purple text-center'>Submit Picks</button>
              {confetti && <Confetti />}
            </div>
  
            <div className='container text-center padtop' style={{ paddingBottom: '10px' }}>
              <p>Total points: <b>{totalPoints}</b></p>
            </div>
  
            {sent && (
              <>
                <div className='alert alert-success Nav-HeaderV8 text-center'>
                  Your picks have been sent!
                </div>
                {confetti && <Confetti gravity={-5.5} />}
              </>
            )}
            {error && <p className='text-danger'>Error submitting picks!</p>}
          </div>
  
          {/* Standings Card for small screens */}
          <div className='col-12 d-lg-none mb-3' style={{ marginTop: '15px' }}>
            <div className='card'>
              <div className='card-body'>
                <h5 className='card-title'><ScottishStandingsPicks /></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default ScottishPicks24;
