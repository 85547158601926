import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import '../App.css';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { MdOutlineSportsRugby, MdSportsRugby } from "react-icons/md";
import { FaFootballBall } from "react-icons/fa";
import { BiFootball } from "react-icons/bi";

function GlobalLeaderboard() {
    const { t } = useTranslation();
    const [euroParticipants, setEuroParticipants] = useState([]);
    const [eplParticipants, setEplParticipants] = useState([]);
    const [spflParticipants, setSPFLParticipants] = useState([]);
    const [championshipParticipants, setChampionshipParticipants] = useState([]);
    const [laligaParticipants, setLaligaParticipants] = useState([]);

    const [showAllEuro, setShowAllEuro] = useState(false);
    const [showAllEpl, setShowAllEpl] = useState(false);
    const [showAllChampionship, setShowAllChampionship] = useState(false);
    const [showAllLaliga, setShowAllLaliga] = useState(false);

    const [showAllSPFL, setShowAllSPFL] = useState(false);
    const [currentSport, setCurrentSport] = useState('');
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            console.log("Logged in user:", user); // Debug log
            setCurrentUser({
                userId: user.uid,
                displayName: user.displayName || user.email,
            });
        }

        const fetchData = async (sport) => {
            try {
                const db = getDatabase();
                let leaderboardRef;

                switch(sport) {
                    case 'EURO':
                        leaderboardRef = ref(db, 'EURO2024TotalPoints');
                        break;
                    case 'EPL':
                        leaderboardRef = ref(db, 'EPL2024TotalPoints');
                        break;
                    case 'Championship':
                        leaderboardRef = ref(db, 'Championship2024TotalPoints');
                        break;
                        case 'La Liga':
                            leaderboardRef = ref(db, 'LaLiga2024TotalPoints');
                            break;
                    case 'SPFL':
                        leaderboardRef = ref(db, 'Scottish2024TotalPoints');
                        break;
                    default:
                        return;
                }

                if (leaderboardRef) {
                    const leaderboardSnapshot = await get(leaderboardRef);
                    const leaderboardData = leaderboardSnapshot.val();

                    if (leaderboardData) {
                        const entries = Object.entries(leaderboardData)
                            .filter(([userId, data]) => data.totalPoints !== undefined && !isNaN(parseInt(data.totalPoints)))
                            .map(([userId, data]) => ({ userId, ...data }));

                        entries.sort((a, b) => b.totalPoints - a.totalPoints);

                        switch(sport) {
                            case 'EURO':
                                setEuroParticipants(entries);
                                break;
                            case 'EPL':
                                setEplParticipants(entries);
                                break;
                            case 'Championship':
                                setChampionshipParticipants(entries);
                                break;
                                case 'La Liga':
                                setLaligaParticipants(entries);
                                break;
                            case 'SPFL':
                                setSPFLParticipants(entries);
                                break;
                        }
                    } else {
                        console.error(`No ${sport} leaderboard data found`);
                    }
                }
            } catch (error) {
                console.error(`Error fetching ${sport} leaderboard data:`, error);
            }
        };

        if (currentSport) {
            fetchData(currentSport);
        }
    }, [currentSport]);

    const extractUsernameFromDisplayName = (displayName) => {
        if (!displayName) {
            return '';
        }
        const match = displayName.match(/\(([^)]+)\)/);
        return match ? match[1] : displayName;
    };

    const handleSportSelection = (sport) => {
        setCurrentSport(sport);
    };

    const handleViewMoreEuro = () => setShowAllEuro(true);
    const handleViewMoreEpl = () => setShowAllEpl(true);
    const handleViewMoreChampionship = () => setShowAllChampionship(true);
    const handleViewMoreLaliga = () => setShowAllLaliga(true);

    const handleViewMoreSPFL = () => setShowAllSPFL(true);

    return (
        <div>
            <Navbar />
            <div className='App-headerV8'>
                <section className="bsb-service-7 py-5 py-xl-8">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-10 col-lg-8 col-xl-7">
                                <h2 className="display-5 mb-5 mb-xl-9 text-center" style={{ color: 'black' }}>{t('global_leaderboard')}</h2>
                                <div className='container text-center'><i>*Select which leaderboard you would like to view</i></div>
                                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                                {currentUser && (
                                    <div className="text-center mb-4">
                                        {/* <strong>User: </strong>{extractUsernameFromDisplayName(currentUser.displayName)} */}
                                    </div>
                                )}
                                <div className="mb-4 d-flex justify-content-between align-items-center">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-purple"><BiFootball /></button>
                                        <button type="button" className="btn btn-outline-purple dropdown-toggle text-light" data-bs-toggle="dropdown" aria-expanded="false">
                                            Football
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><button className="dropdown-item" onClick={() => handleSportSelection('EPL')}>EPL 24/25</button></li>
                                            <li><button className="dropdown-item" onClick={() => handleSportSelection('Championship')}>Championship 24/25</button></li>
                                            <li><button className="dropdown-item" onClick={() => handleSportSelection('SPFL')}>SPFL 24/25</button></li>
                                            <li><button className="dropdown-item" onClick={() => handleSportSelection('La Liga')}>La Liga 24/25</button></li>
                                        </ul>
                                    </div>
                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                        <button type="button" onClick={() => handleSportSelection('Rugby')} className="btn btn-purple"><MdSportsRugby /></button>
                                        <div className="btn-group" role="group">
                                            <button type="button" className="btn btn-outline-purple text-light" onClick={() => handleSportSelection('Rugby')}> Rugby</button>
                                        </div>
                                    </div>
                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                        <button type="button" className="btn btn-purple" onClick={() => handleSportSelection('NFL')}><FaFootballBall /></button>
                                        <div className="btn-group" role="group">
                                            <button type="button" className="btn btn-outline-purple text-light" onClick={() => handleSportSelection('NFL')}> NFL</button>
                                        </div>
                                    </div>
                                </div>
                                {currentSport && (
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title text-center">
                                                {currentSport === 'EURO' ? 'EURO 2024 Leaderboard' :
                                                    currentSport === 'EPL' ? 'EPL 2024/25 Leaderboard' :
                                                    currentSport === 'Championship' ? 'Championship 2024/25 Leaderboard' :
                                                    currentSport === 'SPFL' ? 'SPFL 2024/25 Leaderboard' :
                                                    currentSport === 'La Liga' ? 'La Liga 2024/25 Leaderboard' :

                                                    `${currentSport} Leaderboard`}
                                            </h5>
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>#</th>
                                                            <th className='text-center'>Users</th>
                                                            <th className='text-center'>Points</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-center'>
    {currentSport === 'EURO' && euroParticipants.length > 0 ? (
        euroParticipants.slice(0, showAllEuro ? euroParticipants.length : 10).map((data, index) => {
            const username = extractUsernameFromDisplayName(data.displayName || data.userId);
            const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

            return (
                <tr key={data.userId} className={isCurrentUser ? 'bg-warning' : ''}>
                    <td className='text-center'>{index + 1}</td>
                    <td>
                        {username} {isCurrentUser && '(YOU)'}
                    </td>
                    <td>{data.totalPoints}</td>
                </tr>
            );
        })
    ) : currentSport === 'EPL' && eplParticipants.length > 0 ? (
        eplParticipants.slice(0, showAllEpl ? eplParticipants.length : 10).map((data, index) => {
            const username = extractUsernameFromDisplayName(data.displayName || data.userId);
            const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

            return (
                <tr key={data.userId} className={isCurrentUser ? 'bg-warning' : ''}>
                    <td className='text-center'>{index + 1}</td>
                    <td>
                        {username} {isCurrentUser && '(YOU)'}
                    </td>
                    <td>{data.totalPoints}</td>
                </tr>
            );
        })
    ) : currentSport === 'Championship' && championshipParticipants.length > 0 ? (
        championshipParticipants.slice(0, showAllChampionship ? championshipParticipants.length : 10).map((data, index) => {
            const username = extractUsernameFromDisplayName(data.displayName || data.userId);
            const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

            return (
                <tr key={data.userId} className={isCurrentUser ? 'bg-warning' : ''}>
                <td className='text-center'>{index + 1}</td>
                <td>
                    {username} {isCurrentUser && '(YOU)'}
                </td>
                <td>{data.totalPoints}</td>
            </tr>
            
            );
        })
    ) :
    currentSport === 'La Liga' && laligaParticipants.length > 0 ? (
        laligaParticipants.slice(0, showAllLaliga ? laligaParticipants.length : 10).map((data, index) => {
            const username = extractUsernameFromDisplayName(data.displayName || data.userId);
            const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

            return (
                <tr key={data.userId} className={isCurrentUser ? 'bg-warning' : ''}>
                <td className='text-center'>{index + 1}</td>
                <td>
                    {username} {isCurrentUser && '(YOU)'}
                </td>
                <td>{data.totalPoints}</td>
            </tr>
            
            );
        })
    ) :
    currentSport === 'SPFL' && spflParticipants.length > 0 ? (
        spflParticipants.slice(0, showAllSPFL ? spflParticipants.length : 10).map((data, index) => {
            const username = extractUsernameFromDisplayName(data.displayName || data.userId);
            const isCurrentUser = username === extractUsernameFromDisplayName(currentUser?.displayName);

            return (
                <tr key={data.userId} className={isCurrentUser ? 'bg-warning' : ''}>
                    <td className='text-center'>{index + 1}</td>
                    <td>
                        {username} {isCurrentUser && '(YOU)'}
                    </td>
                    <td>{data.totalPoints}</td>
                </tr>
            );
        })
    ) : (
        <tr>
            <td colSpan="3" className="text-center">No data available</td>
        </tr>
    )}
</tbody>


                                                </table>
                                            </div>
                                            {currentSport === 'EURO' && euroParticipants.length > 10 && !showAllEuro && (
                                                <div className="text-center">
                                                    <button onClick={handleViewMoreEuro} className="btn btn-purple btn-sm">{t('view_more')}</button>
                                                </div>
                                            )}
                                            {currentSport === 'EPL' && eplParticipants.length > 10 && !showAllEpl && (
                                                <div className="text-center">
                                                    <button onClick={handleViewMoreEpl} className="btn btn-purple btn-sm">{t('view_more')}</button>
                                                </div>
                                            )}
                                            {currentSport === 'Championship' && championshipParticipants.length > 10 && !showAllChampionship && (
                                                <div className="text-center">
                                                    <button onClick={handleViewMoreChampionship} className="btn btn-purple btn-sm">{t('view_more')}</button>
                                                </div>
                                            )}
                                            {currentSport === 'La Liga' && laligaParticipants.length > 10 && !showAllLaliga && (
                                                <div className="text-center">
                                                    <button onClick={handleViewMoreLaliga} className="btn btn-purple btn-sm">{t('view_more')}</button>
                                                </div>
                                            )}
                                            {currentSport === 'SPFL' && spflParticipants.length > 10 && !showAllSPFL && (
                                                <div className="text-center">
                                                    <button onClick={handleViewMoreSPFL} className="btn btn-purple btn-sm">{t('view_more')}</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default GlobalLeaderboard;
