import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom'; // Should be imported as BrowserRouter

import Home from './Home';
import News from './News';
import SignUp from './auth/SignUp';
import LogIn from './auth/LogIn';
import Contact from './Contact';
import AuthDetails from './auth/AuthDetails';
import UserProfile from './auth/UserProfile';
import ProtectedRoute from './ProtectedRoute';
import Social from './Social';
import PremierLeague from './Tournaments/PremierLeague';

import PassReset from './auth/PassReset';
import Fixture from './Tournaments/Fixture';
import Test from './Tournaments/test';
import Testapi from './Tournaments/testapi';
import Dashboard from './Tournaments/Dashboard';
import JoinBracket from './Tournaments/JoinBracket';
import CreateBracket from './Tournaments/CreateBracket';
import JoinedBracket from './Tournaments/JoinedBracket';
import FixturesForJoinedBracket from './Tournaments/FixturesForJoinedBracket';
import PremierLeagueForJoinedBrackets from './Tournaments/PremierLeagueForJoinedBracket';
import FAQ from './FAQ';
import Testing from './Tournaments/Testing';
import Standings from './Tournaments/Standings';
import LoginToJoinBracket from './Tournaments/LoginToJoinBracket';
import Login1 from './auth/Login1';
import Signup1 from './auth/Signup1';
import Signupgoogle from './auth/SignupGoogle2';
import Confirmation from './auth/Confirmation';
import Invitations from './Invitations';
import HomeTeamFixtures from './Team-stats/Home.Team.Fixtures';
import PointsSystem from './Tournaments/PointsSystem';
import ScotishStandingsOldAPI from './Tournaments/ScottishStandingsOldAPI';
import ScottishStandingsNewAPI from './Tournaments/ScottishStandingsNewAPI';
import RangersSchedule from './Tournaments/RangersSchedule';
import Referees from './Tournaments/Referees';
import Players from './Tournaments/Players';
import Coaches from './Tournaments/Coaches';
import WorldCup2022 from './Tournaments/WorldCup2022';
import NetherlandsStats from './Team-stats/NetherlandsStats';
import WorldCup2022Picks from './Tournaments/WorldCup2022Picks';
import WorldCup2022PicksBracket from './Tournaments/WorldCup2022PicksBracket';
import ScottishPremiershipPicksBracket from './Tournaments/ScottishPremiershipPicksBracket';
import GlobalLeaderboard from './Tournaments/GlobalLeaderboard';
import CreateArticle from './Admin/CreateArticle';
import Euro2024Fixtures from './Tournaments/Euro2024Fixtures'
import Euro2024Picks from './Tournaments/Euro2024Picks';
import Euro2024FixturesBracket from './Tournaments/Euro2024FixturesBracket';
import Euro2024PicksBracket from './Tournaments/Euro2024PicksBracket';
import Euro2024Standings from './Tournaments/Euro2024Standings';
import ScrollToTop from './ScrollToTop';
import Post from './post';
import ArticleDetail from './ArticleDetail';
import TestingCalculation from './TestingCalculation';
import Error from './Error';
import EuroLearnMore from './EuroLearnMore';
import EPLLearnMore from './EPLLearnMore';
import Terms from './Terms';
import Privacy from './Privacy';
import ScottishFixtures24 from './Tournaments/ScottishFixtures24';
import ScottishPicks24 from './Tournaments/ScottishPicks24';
import SPFLLearnMore from './SPFLLearnMore';
import ChampionshipStandings from './Tournaments/ChampionshipStandings';
import ScottishFixturesBracket from './Tournaments/ScottishFixturesBracket';
import ChampionshipFixtures from './Tournaments/ChampionshipFixtures';
import ChampionshipPicks from './Tournaments/ChampionshipPicks';
import ChampionshipLearnMore from './ChampionshipLearnMore';
import ChampionshipFixturesBracket from './Tournaments/ChampionshipFixturesBracket';
import ChampionshipPicksBracket  from './Tournaments/ChampionshipPicksBracket';
import EPLStandingsPicks from './Tournaments/EPLStandingsPicks';
// Team Stats
import ManUnited from './Team-stats/ManUnitedStats';
import ManCity from './Team-stats/ManCityStats';
import Arsenal from './Team-stats/ArsenalStats';
import Liverpool from './Team-stats/LiverpoolStats';
import AstonVilla from './Team-stats/AstonVillaStats';
import Tottenham from './Team-stats/TottenhamStats';
import WestHam from './Team-stats/WestHamStats';
import Brighton from './Team-stats/BrightonStats';
import Wolves from './Team-stats/WolvesStats';
import Newcastle from './Team-stats/NewcastleStats';
import Chelsea from './Team-stats/ChelseaStats';
import Fulham from './Team-stats/FulhamStats';
import Bournemouth from './Team-stats/BournemouthStats';
import Everton from './Team-stats/EvertonStats';
import CrystalPalace from './Team-stats/CrystalPalace';
import Brentford from './Team-stats/BrentfordStats';
import Southampton from './Team-stats/SouthamptonStats';
import NottinghamForrest from './Team-stats/NottinghamForestStats';
import Luton from './Team-stats/LutonStats';
import Burnley from './Team-stats/BurnleyStats';
import SheffieldUtd from './Team-stats/SheffieldUtd';
import Ipswitch from './Team-stats/IpswichStats';
import Leicester from './Team-stats/LeicesterStats';
import Blackburn from './Team-stats/BlackburnStats';
import Bristol from './Team-stats/BristolStats';
import Cardiff from './Team-stats/CardiffStats';
import Conventry from './Team-stats/ConventryStats';
import Derby from './Team-stats/DerbyStats';
import Hull from './Team-stats/HullStats';
import Leeds from './Team-stats/LeedsStats';
import Middlesbrough from './Team-stats/MiddlesbroughStats';
import Millwall from './Team-stats/MillwallStats';
import Norwich from './Team-stats/NorwichStats';
import Oxford from './Team-stats/OxfordStats';
import Plymouth from './Team-stats/PlymouthStats';
import Porstsmouth from './Team-stats/PortsmouthStats';
import Preston from './Team-stats/PrestonStats';
import QPR from './Team-stats/QPRStats';
import SheffieldWed from './Team-stats/SheffieldWed';
import Stoke from './Team-stats/StokeStats';
import Sunderland from './Team-stats/SunderlandStats';
import Swansea from './Team-stats/SwanseaStats';
import Watford from './Team-stats/WatfordStats';
import WestBrom from './Team-stats/WestBromStats';
import AdComponent from './AdComponent';
import ChampionshipStandingsPicks from './Tournaments/ChampionshipStandingsPicks';
import ScottishStandingsPicks from './Tournaments/ScottishStandingsPicks';
import CelticStats from './Team-stats/CelticStats';
import StMirrenStats from './Team-stats/StMirrenStats';
import AberdeenStats from './Team-stats/AberdeenStats';
import HeartsStats from './Team-stats/Hearts';
import MotherwellStats from './Team-stats/MotherwellStats';
import Rangers from './Team-stats/RangersStats';
import RossCounty from './Team-stats/RossCounty';
import DundeeUtdStats from './Team-stats/DundeeUtdStats';
import DundeeStats from './Team-stats/DundeeStats';
import HibernianStats from './Team-stats/HibernianStats';
import KilmarnockStats from './Team-stats/KilmarnockStats';
import StJohnstoneStats from './Team-stats/StJohnstoneStats';
import Admin from './Admin/Admin';
import Sidebar from './Admin/Sidebar';
import Users from './Admin/Users';
import Settings from './Admin/Settings';
import ActiveUsers from './Admin/ActiveUsers';
import Weather from './Weather';
import LaLigaPicks from './Tournaments/LaLigaPicks';
import LaLigaStandings from './Tournaments/LaLigaStandings';
import LaLigaStandingsPicks from './Tournaments/LaLigaStandingsPicks';
import LaLigaPicksBracket from './Tournaments/LaLigaPicksBracket';
import LaLigaFixturesBracket from './Tournaments/LaLigaFixturesBracket';
import LaLigaFixtures from './Tournaments/LaLigaFixtures';
import LaLigaLearnMore from './LaLigaLearnMore';
// Team Stats


export default function App() {

  const pageSettings = {
    faq: {
      visible: true,
      accessible: true,
    },
  }

  return (
    <BrowserRouter>
          <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/loginn" element={<Login1 />} />
        <Route path="/signup1" element={<Signup1 />} />
        <Route path="/signup-google" element={<Signupgoogle />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/post" element={<Post />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/social" element={<Social />} />
        <Route path="/authdetails" element={<AuthDetails />} />
        <Route path="/passreset" element={<PassReset />} />
        <Route path="/pointssystem" element={<PointsSystem />} />
        <Route path="/referees" element={<Referees />} />
        <Route path="/worldcup2022" element={<WorldCup2022 />} />
        <Route path="/netherlandsstats" element={<NetherlandsStats />} />
        <Route path="/worldcup2022picks" element={<WorldCup2022Picks />} />
        <Route path="/worldcup2022picksbracket" element={<WorldCup2022PicksBracket />} />
        <Route path="hometeamfixtures" element={<HomeTeamFixtures />} />
        <Route path="scottishstandingsoldapi" element={<ScotishStandingsOldAPI />} />
        <Route path="scottishstandingsnewapi" element={<ScottishStandingsNewAPI />} />
        <Route path="rangersschedule" element={<RangersSchedule />} />
        <Route path="players" element={<Players />} />
        <Route path="coaches" element={<Coaches />} />
        <Route path="/ScottishPremiershipPicksBracket" element={<ScottishPremiershipPicksBracket />} />
        <Route path="/laligapicks" element={<LaLigaPicks />} />
        <Route path="/laligastandings" element={<LaLigaStandings />} />
        <Route path="/laligastandingspicks" element={<LaLigaStandingsPicks />} />
        <Route path="/laligastandingspicksbracket" element={<LaLigaPicksBracket />} />
        <Route path="/laligafixturesbracket" element={<LaLigaFixturesBracket />} />
        <Route path="/laligafixtures" element={<LaLigaFixtures />} />
        <Route path="/laligalearnmore" element={<LaLigaLearnMore />} />

        <Route path="/ScottishFixturesBracket" element={<ScottishFixturesBracket />} />
        <Route path="/test" element={<Test />} />
        <Route path="/weather" element={<Weather />} />

        <Route path="/testapi" element={<Testapi />} />
        {pageSettings.faq.accessible && (
  <Route path="/faq" element={<FAQ />} />
)}

              <Route path="/testing" element={<Testing />} />
        <Route path="/testingcalculation" element={<TestingCalculation />} />
        <Route path="/logintojoinbracket" element={<LoginToJoinBracket />} />
        <Route path="/eurolearnmore" element={<EuroLearnMore />} />
        <Route path="/epllearnmore" element={<EPLLearnMore />} />
        <Route path="/spfllearnmore" element={<SPFLLearnMore />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/euro2024fixtures" element={<Euro2024Fixtures />} />
        <Route path="/euro2024standings" element={<Euro2024Standings />} />
        <Route path="/scottishfixtures24" element={<ScottishFixtures24 />} />
        <Route path="/scottishpicks24" element={<ScottishPicks24 />} />
        <Route path="/championshipstandings" element={<ChampionshipStandings />} />
        <Route path="/championshipfixtures" element={<ChampionshipFixtures />} />
        <Route path="/championshippicks" element={<ChampionshipPicks />} />
        <Route path="/championshiplearnmore" element={<ChampionshipLearnMore />} />
        <Route path="/eplstandingspicks" element={<EPLStandingsPicks />} />
        <Route path="/admin/dashboard" element={<Admin />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/settings" element={<Settings />} />
        <Route path="/admin/activeusers" element={<ActiveUsers />} />

        <Route path="/sidebar" element={<Sidebar />} />

        <Route path="/article/:id" element={<ArticleDetail />} />
        <Route path="/*" element={<Error />} />
        <Route path="/ad" element={<AdComponent />} />
        <Route path="/championshipstandingspicks" element={<ChampionshipStandingsPicks />} />
        <Route path="/scottishstandingspicks" element={<ScottishStandingsPicks />} />

        <Route path="/bracket/:bracketId/:nameOfBracket/participants" element={<JoinedBracket />} />
 
        {/*Protected Routes*/} 
        <Route element={<ProtectedRoute />}>
          {/*Bracket Related Route*/}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/joinhuddle" element={<JoinBracket />} />
        <Route path="/globalleaderboard" element={<GlobalLeaderboard />} />
        <Route path="/FixturesForJoinedBracket" element={<FixturesForJoinedBracket />} />
        <Route path="/PremierLeagueForJoinedBrackets" element={<PremierLeagueForJoinedBrackets />} />
        <Route path="/euro2024fixturesbracket" element={<Euro2024FixturesBracket />} />
        <Route path="/euro2024picksbracket" element={<Euro2024PicksBracket />} />
        <Route path="/createhuddle" element={<CreateBracket />} />
        <Route path="/invitations" element={<Invitations />} />


          {/*Bracket Related Route*/} 
        <Route path="/premierleague" element={<PremierLeague />} />
        <Route path="/createarticle" element={<CreateArticle />} />
        <Route path="/fixture" element={<Fixture />} />
        <Route path="/standings" element={<Standings />} />
        <Route path="/euro2024picks" element={<Euro2024Picks />} />
        <Route path="/userprofile" element={<UserProfile />} />

        {/* Team Stats*/}
        <Route path="/manunited" element={<ManUnited />} />
        <Route path="/arsenal" element={<Arsenal />} />
        <Route path="/liverpool" element={<Liverpool />} />
        <Route path="/mancity" element={<ManCity />} />
        <Route path="/astonvilla" element={<AstonVilla />} />
        <Route path="/tottenham" element={<Tottenham />} />
        <Route path="/westham" element={<WestHam />} />
        <Route path="/brighton" element={<Brighton />} />
        <Route path="/wolves" element={<Wolves />} />
        <Route path="/newcastle" element={<Newcastle />} />
        <Route path="/chelsea" element={<Chelsea />} />
        <Route path="/ipswich" element={<Ipswitch />} />
        <Route path="/fulham" element={<Fulham />} />
        <Route path="/bournemouth" element={<Bournemouth />} />
        <Route path="/everton" element={<Everton />} />
        <Route path="/crystalpalace" element={<CrystalPalace />} />
        <Route path="/brentford" element={<Brentford />} />
        <Route path="/nottinghamforest" element={<NottinghamForrest />} />
        <Route path="/luton" element={<Luton />} />
        <Route path="/burnley" element={<Burnley />} />
        <Route path="/sheffieldutd" element={<SheffieldUtd />} />
        <Route path="/Southampton" element={<Southampton />} />
        <Route path="/leicester" element={<Leicester />} />
        <Route path="/blackburn" element={<Blackburn />} />
        <Route path="/bristol" element={<Bristol />} />
        <Route path="/cardiff" element={<Cardiff />} />
        <Route path="/coventry" element={<Conventry />} />
        <Route path="/derby" element={<Derby />} />
        <Route path="/hull" element={<Hull />} />
        <Route path="/leeds" element={<Leeds />} />
        <Route path="/middlesbrough" element={<Middlesbrough />} />
        <Route path="/millwall" element={<Millwall />} />
        <Route path="/norwich" element={<Norwich />} />
        <Route path="/oxford" element={<Oxford />} />
        <Route path="/plymouth" element={<Plymouth />} />
        <Route path="/portsmouth" element={<Porstsmouth />} />
        <Route path="/preston" element={<Preston />} />
        <Route path="/qpr" element={<QPR />} />
        <Route path="/sheffieldwed" element={<SheffieldWed />} />
        <Route path="/stoke" element={<Stoke />} />
        <Route path="/sunderland" element={<Sunderland />} />
        <Route path="/Swansea" element={<Swansea />} />
        <Route path="/watford" element={<Watford />} />
        <Route path="/westbrom" element={<WestBrom />} />
        <Route path="/celtic" element={<CelticStats />} />
        <Route path="/stmirren" element={<StMirrenStats />} />
        <Route path="/aberdeen" element={<AberdeenStats />} />
        <Route path="/hearts" element={<HeartsStats />} />
        <Route path="/motherwell" element={<MotherwellStats />} />
        <Route path="/rangers" element={<Rangers />} />
        <Route path="/rosscounty" element={<RossCounty />} />
        <Route path="/dundeeutd" element={<DundeeUtdStats />} />
        <Route path="/dundee" element={<DundeeStats />} />
        <Route path="/hibernian" element={<HibernianStats />} />
        <Route path="/kilmarnock" element={<KilmarnockStats />} />
        <Route path="/stjohnstone" element={<StJohnstoneStats />} />

        {/* Team Stats*/}
        </Route>
        {/*Protected Routes*/}

      </Routes>
    </BrowserRouter>
  );
}

