import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function FAQ() {

  const { t } = useTranslation();

  return (
    <div>
      <Navbar />
      <div className='App-headerV3'>
        <section className="bsb-faq-2 py-3 py-md-5 py-xl-8 padbot">
          <div className="container">
            <div className="row gy-5 gy-lg-0">
              <div className="col-12 col-lg-6">
              <h2 className="h1 mb-3 text-dark">{t('how_can_we_help')}</h2>
              <p className="lead fs-4 mb-4" >{t('hope_found_answer')}</p>
                <Link to='/contact' className="btn btn-lg bsb-btn-2xl btn-outline-dark">{t('still_have_issue')}</Link>
              </div>
              <div className="col-12 col-lg-6" >
                <div className="row justify-content-xl-end">
                  <div className="col-12 col-xl-11" >
                    <div className="accordion accordion-flush "  id="accordionExample">
                      <div className="accordion-item mb-4 shadow-sm bg border border-dark " style={{backgroundColor: '#fff'}}>
                        <h2 className="accordion-header" id="headingOne" style={{ color: 'black' }} >
                          <button className="accordion-button bg-transparent fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{ color: 'black' }}>
                          {t('how_to_play')} 
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{ color: 'black' }}>
                          <div className="accordion-body">
                          <p >{t('welcomeFAQ')}</p>
                            <br />
                            <p>{t('how_to_playFAQ')}</p>
                            <br />
                                                </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 shadow-sm bg- border border-dark">
                        <h2 className="accordion-header" id="headingJoin">
                          <button className="accordion-button collapsed bg-transparent fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJoin" aria-expanded="false" aria-controls="collapseTwo" style={{ color: 'black' }}>
                          <p>{t('join_a_huddle')}</p>
                          </button>
                        </h2>
                        <div id="collapseJoin" className="accordion-collapse collapse" aria-labelledby="headingJoin">
                          <div className="accordion-body" style={{ color: 'black' }}>
                          {t('join_huddle_steps')}
                                                                              </div>
                          <ul>
                          <li>{t('join_step_1')}</li>
  <li>{t('join_step_2')}</li>
  <li>{t('join_step_3')}</li>
                            <br />
                          </ul>
                          <div className='container'>
                          <p>{t('search_filter_description')}</p>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 shadow-sm bg- border border-dark">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed bg-transparent fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{ color: 'black' }}>
                          {t('create_huddle')}                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo">
                          <div className="accordion-body" style={{ color: 'black' }}>
                          {t('create_huddle_steps')}                          </div>
                          <ul>
                          <li>{t('create_huddle_step_1')}</li>
  <li>{t('create_huddle_step_2')}</li>
  <li>{t('create_huddle_step_3')}</li>
  <li>Step 4: Once your Huddle has been created you will be able to find it in your Player Dashboard, which can be accessed by going to Dashboard under the Huddles Tab.</li>
                            <br />
                          </ul>
                          <div className='container'>
                          <p>{t('ready_to_play')}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 shadow-sm bg- border border-dark">
                        <h2 className="accordion-header" id="headingPrediction">
                          <button className="accordion-button collapsed bg-transparent fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrediction" aria-expanded="false" aria-controls="collapseTwo" style={{ color: 'black' }}>
                          {t('make_predictions')}                          </button>
                        </h2>
                        <div id="collapsePrediction" className="accordion-collapse collapse" aria-labelledby="headingPrediction">
                          <div className="accordion-body" style={{ color: 'black' }}>
                          {t('set_predictions')}
                                                      </div>
                     
                          <div className='container'>
                            <br />
                            <p>{t('open_event_predictions')}</p>

                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 shadow-sm bg- border border-dark">
                        <h2 className="accordion-header" id="headingThree">
                          <button className="accordion-button collapsed bg-transparent fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{ color: 'black' }}>
                          {t('scoring_workFAQ')}                                               </button>
                        </h2>
                        
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree">
                          <div className="accordion-body" style={{ color: 'black' }}>
                          {t('compete_with_users')}                          </div>
                          <div className='container'>
                          {t('prediction_accuracy')}                          </div>
                          <div className='container'>
                            <ul>
                            <li>{t('three_pointsFAQ')}</li>
  <li>{t('one_pointFAQ')}</li>
  <li>{t('zero_pointsFAQ')}</li>
                              <br />
                            </ul>
                            <p>
                            {t('prediction_check')}                            </p>
                            <br />
                            <p>{t('points_display')}</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 shadow-sm bg- border border-dark">
                        <h2 className="accordion-header" id="headingFour">
                          <button className="accordion-button collapsed bg-transparent fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{ color: 'black' }}>
                          {t('create_account')}                          </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour">
                          <div className="accordion-body" style={{ color: 'black' }}>
                          {t('create_account_process')}                            <br />
                          <div className='padtop'>{t('alternative_link')}</div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 shadow-sm bg- border border-dark">
                        <h2 className="accordion-header" id="headingFive">
                          <button className="accordion-button collapsed bg-transparent fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" style={{ color: 'black' }}>
                          {t('payment_question')}                          </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" style={{ color: 'black' }}>
                          <div className="accordion-body">
                          <p>{t('free_to_use')}</p>
              
                  </div>
                </div>
              </div>
              <br />
              <div className="accordion-item shadow-sm bg- border border-dark">
                <h2 className="accordion-header" id="headingSix">
                  <button className="accordion-button collapsed bg-transparent fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseFive" style={{color: 'black'}}>
                  {t('invite_friends')}
                                    </button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" style={{color: 'black'}}>
                  <div className="accordion-body">
                  <p>Inviting your friends to join your Huddle has never been easier! Simply go to your Huddle and click on either the 
                  "Copy Invite Link" or "Share on Facebook"</p>
                    <br />
                    <p>Clicking on "Copy Invite Link" will give you a link that you can send to your friends via any means you like, such as WhatsApp, Messenger, Text or Email!</p>
                    <p>Using the "Share on Facebook" button will open a new tab that will prompt you to share a link via a Facebook post. All you need to do is enter some text and click on Share!</p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
    <Footer />
    </div>

  )
}

export default FAQ