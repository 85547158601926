


import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';

import 	Blackburn from '../Team-Kits-Images/Blackburn.png'
import Derby from '../Team-Kits-Images/Derby.png'
import 	Preston from '../Team-Kits-Images/Preston.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Cardiff from '../Team-Kits-Images/Cardiff.png'
import Sunderland from '../Team-Kits-Images/Sunderland.png'
import 	Hull from '../Team-Kits-Images/Hull.png'
import 	Bristol from '../Team-Kits-Images/Bristol.png'
import 	Portsmouth from '../Team-Kits-Images/Portsmouth.png'
import Middlesbrough	 from '../Team-Kits-Images/Middlesbrough.png'
import Swansea	 from '../Team-Kits-Images/Swansea.png'
import Millwall	 from '../Team-Kits-Images/Millwall.png'
import Watford		 from '../Team-Kits-Images/Watford.png'
import Oxford	 from '../Team-Kits-Images/Oxford.png'
import Norwich	 from '../Team-Kits-Images/Norwich.png'
import QPR	 from '../Team-Kits-Images/QPR.png'
import WestBrom	 from '../Team-Kits-Images/WestBrom.png'
import Stoke	 from '../Team-Kits-Images/Stoke.png'
import Coventry	 from '../Team-Kits-Images/Coventry.png'
import Wednesday	 from '../Team-Kits-Images/Wednesday.png'
import Plymouth	 from '../Team-Kits-Images/Plymouth.png'
import Luton	 from '../Team-Kits-Images/LutonTown.png'
import Burnley	 from '../Team-Kits-Images/Burnley.png'
import Leeds	 from '../Team-Kits-Images/Leeds.png'
import { useTranslation } from 'react-i18next';



function ChampionshipFixturesBracket() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const { t } = useTranslation();


    useEffect(() => {
        const fetchFixturesAndPicks = async () => {
            try {
                // Fetch fixtures data from the API
                const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/2024", {
                    headers: {
                        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                    }
                });
                const fixturesData = await fixturesResponse.json();
                console.log("Fetched fixtures data:", fixturesData);
    
                // Fetch user's picks
                if (userId) {
                    const picksRef = ref(getDatabase(), `picks/${userId}`);
                    const snapshot = await get(picksRef);
                    const picksData = snapshot.val();
    
                    if (picksData) {
                        setInputsData(picksData);
                    }
                }
    
                // Find the current round
                const now = new Date();
                const currentRound = rounds.find(round => now >= round.startDate && now <= round.endDate);
                console.log("Current Round:", currentRound); // Log the current round to verify
    
                // Filter fixtures for the current round
                const fixturesForCurrentRound = fixturesData.filter(fixture => {
                    const fixtureDate = new Date(fixture.fixture.date);
                    return fixtureDate >= currentRound.startDate && fixtureDate <= currentRound.endDate;
                });
    
                // Sort the fixtures for the current round by date
                fixturesForCurrentRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
                // Set the fixtures state with the fixtures for the current round
                setFixtures(fixturesForCurrentRound);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { homeTeam, awayTeam }
        }));
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2024-07-03'), endDate: new Date('2024-08-12') },
        { number: 2, startDate: new Date('2024-08-12'), endDate: new Date('2024-08-18') },
        { number: 3, startDate: new Date('2024-08-18'), endDate: new Date('2024-08-25') },
        { number: 4, startDate: new Date('2024-08-25'), endDate: new Date('2024-09-01') },
        { number: 5, startDate: new Date('2024-09-01'), endDate: new Date('2024-09-15') },
        { number: 6, startDate: new Date('2024-09-15'), endDate: new Date('2024-09-22') },
        { number: 7, startDate: new Date('2024-09-22'), endDate: new Date('2024-09-29') },
        { number: 8, startDate: new Date('2024-09-29'), endDate: new Date('2024-10-02') },
        { number: 9, startDate: new Date('2024-10-02'), endDate: new Date('2024-10-05') },
        { number: 10, startDate: new Date('2024-10-05'), endDate: new Date('2024-10-19') },
        { number: 11, startDate: new Date('2024-10-19'), endDate: new Date('2024-10-23') },
        { number: 12, startDate: new Date('2024-10-23'), endDate: new Date('2024-10-26') },
        { number: 13, startDate: new Date('2024-10-26'), endDate: new Date('2024-11-02') },
        { number: 14, startDate: new Date('2024-11-02'), endDate: new Date('2024-11-06') },
        { number: 15, startDate: new Date('2024-11-06'), endDate: new Date('2024-11-09') },
        { number: 16, startDate: new Date('2024-11-09'), endDate: new Date('2024-11-23') },
        { number: 17, startDate: new Date('2024-11-23'), endDate: new Date('2024-11-27') },
        { number: 18, startDate: new Date('2024-11-27'), endDate: new Date('2024-11-30') },
        { number: 19, startDate: new Date('2024-11-30'), endDate: new Date('2024-12-07') },
        { number: 20, startDate: new Date('2024-12-07'), endDate: new Date('2024-12-11') },
        { number: 21, startDate: new Date('2024-12-11'), endDate: new Date('2024-12-14') },
        { number: 22, startDate: new Date('2024-12-14'), endDate: new Date('2024-12-21') },
        { number: 23, startDate: new Date('2024-12-21'), endDate: new Date('2025-12-26') },
        { number: 24, startDate: new Date('2025-12-26'), endDate: new Date('2025-12-29') },
        { number: 25, startDate: new Date('2025-12-29'), endDate: new Date('2025-01-01') },
        { number: 26, startDate: new Date('2025-01-01'), endDate: new Date('2025-01-04') },
        { number: 27, startDate: new Date('2025-01-04'), endDate: new Date('2025-01-18') },
        { number: 28, startDate: new Date('2025-01-18'), endDate: new Date('2025-01-22') },
        { number: 29, startDate: new Date('2025-01-22'), endDate: new Date('2025-01-25') },
        { number: 30, startDate: new Date('2025-01-25'), endDate: new Date('2025-02-01') },
        { number: 31, startDate: new Date('2025-02-01'), endDate: new Date('2025-02-08') },
        { number: 32, startDate: new Date('2025-02-08'), endDate: new Date('2025-02-12') },
        { number: 33, startDate: new Date('2025-02-12'), endDate: new Date('2025-02-15') },
        { number: 34, startDate: new Date('2025-02-15'), endDate: new Date('2025-02-22') },
        { number: 35, startDate: new Date('2025-02-22'), endDate: new Date('2025-03-01') },
        { number: 36, startDate: new Date('2025-03-01'), endDate: new Date('2025-03-08') },
        { number: 37, startDate: new Date('2025-03-08'), endDate: new Date('2025-03-12') },
        { number: 38, startDate: new Date('2025-03-12'), endDate: new Date('2025-03-15') },
        { number: 39, startDate: new Date('2025-03-15'), endDate: new Date('2025-03-29') },
        { number: 40, startDate: new Date('2025-03-29'), endDate: new Date('2025-04-05') },
        { number: 41, startDate: new Date('2025-04-05'), endDate: new Date('2025-04-09') },
        { number: 42, startDate: new Date('2025-04-09'), endDate: new Date('2025-04-12') },
        { number: 43, startDate: new Date('2025-04-12'), endDate: new Date('2025-04-18') },
        { number: 44, startDate: new Date('2025-04-18'), endDate: new Date('2025-04-21') },
        { number: 45, startDate: new Date('2025-04-21'), endDate: new Date('2025-04-26') },
        { number: 46, startDate: new Date('2025-04-26'), endDate: new Date('2025-05-03') }
    ];
    
    

    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/2024?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    
    const teamInitials = {
        'Blackburn': 'BLB',
        'Bristol City': 'BRC',
        'Cardiff': 'CAR',
        'Coventry': 'COV',
        'Hull City': 'HUL',
        'Middlesbrough': 'MID',
        'Millwall': 'MIL',
        'Norwich': 'NOR',
        'Plymouth': 'PLY',
        'Portsmouth': 'POR',
        'Preston': 'PRE',
        'QPR': 'QPR',
        'Sheffield Wednesday': 'SHW',
        'Stoke': 'STO',
        'Sunderland': 'SUN',
        'Swansea': 'SWA',
        'Watford': 'WAT',
        'West Brom': 'WBA',
        'Leeds': 'LEE',
        'Derby': 'DER',
        'Sheffield Utd': 'SHU',
        'Oxford United': 'OXF',
        'Luton': 'LUT',
        'Burnley': 'BUR'
    };
    
      
      
    
      const renderTeamInitials = (teamName) => {
        return teamInitials[teamName] || teamName;
      };
    
      const teamLogos = {
        'Blackburn': Blackburn,
        'Bristol City': Bristol,
        'Cardiff': Cardiff,
        'Coventry': Coventry,
        'Hull City': Hull,
        'Middlesbrough': Middlesbrough,
        'Millwall': Millwall,
        'Norwich': Norwich,
        'Plymouth': Plymouth,
        'Portsmouth': Portsmouth,
        'Preston': Preston,
        'QPR': QPR,
        'Sheffield Wednesday': Wednesday,
        'Stoke': Stoke,
        'Sunderland': Sunderland,
        'Swansea': Swansea,
        'Watford': Watford,
        'West Brom': WestBrom,
        'Leeds': Leeds,
        'Derby': Derby,
        'Sheffield Utd': SheffieldUnited,
        'Oxford United': Oxford,
        'Stoke City': Stoke,
        'Luton': Luton,
        'Burnley': Burnley,
    
    
    
    
      };
      
    
    const renderTeamLogo = (teamName) => {
    const logo = teamLogos[teamName];
    if (logo) {
      return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
    }
    return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
    };
    
    
    return (
        <div className=''>
            <div className=''>
            <h2 className="text-dark text-center padtop mb-3 ">Championship Fixtures</h2>
            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown-center text-center padbot">
                    <button className="btn btn-custom dropdown-toggle btn mb-2 text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu text-center custom" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className=" mb-3" style={{marginBottom: '15px'}}>
                    <div className='mb-2'>
                       
                            <div className='mb-2 '>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3 col-lg-12 mx-auto card' style={{ backgroundColor: '#fff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row">
                                        <div className="col text-center"> 
                                        {/* Display kit of home team */}
              {renderTeamLogo(fixture.teams.home.name)}

{/* Display kit of home team */}
                                        </div>


                                        <>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
      disabled
    />
  </div>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
      disabled
    />
  </div>
</>



                                            <div className="col text-center"> 
                                        {/* Display kit of away team */}
                    {renderTeamLogo(fixture.teams.away.name)}

{/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                     {/* Display home team Initials */}

                                        <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>

{/* Display home team Initials */}

                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                  {/* Display away team Initials */}
                    <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>

{/* Display away team Initials */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center mb-2">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                    </div>
                                ))}
                                                                    <div></div>

                            </div>
                    </div>
                </div>
            </div>
{/* <div className='mb-2 padtop'></div> */}
        </div>
    );
}

export default ChampionshipFixturesBracket;
