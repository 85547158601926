import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef, useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Confetti from 'react-confetti';
import StandingsCard from './Standings'; // Move standings card to its own component

const TestApi = () => {
  const [fixtures, setFixtures] = useState([]);
  const [userPicks, setUserPicks] = useState({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state
  const [selectedMatchday, setSelectedMatchday] = useState('Round 1');

  const { t } = useTranslation();
  const auth = getAuth();
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const [dateRanges] = useState([
    { start: '2024-06-11', end: '2024-08-20' },
    { start: '2024-08-20', end: '2024-08-26' },
    { start: '2024-08-26', end: '2024-09-02' },
    { start: '2024-09-02', end: '2024-09-15' },
    // Additional matchdays as needed
  ]);

  // Fetch fixtures and set loading to false
  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const response = await fetch("https://iga-sport.azurewebsites.net/api/league/253/fixtures/season/2024", {
          headers: { 'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg==' }
        });
        const data = await response.json();
        setFixtures(data);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      } finally {
        setLoading(false); // Stop loading after fetch is complete
      }
    };
    fetchFixtures();
  }, []);

  // Filter fixtures by selected matchday
  useEffect(() => {
    if (fixtures.length > 0 && currentPage > 0) {
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      const filtered = fixtures.filter((fixture) => {
        const fixtureDate = new Date(fixture.fixture.date);
        return fixtureDate >= startDate && fixtureDate <= endDate;
      });
      setFilteredFixtures(filtered);
    }
  }, [currentPage, fixtures, dateRanges]);

  const handleMatchdaySelect = (index) => {
    setCurrentPage(index + 1);
    setSelectedMatchday(`Round ${index + 1}`);
  };

  const handlePickChange = (fixtureId, teamType, value) => {
    const updatedPicks = {
      ...userPicks,
      [fixtureId]: { ...userPicks[fixtureId], [teamType]: value },
    };
    setUserPicks(updatedPicks);
  };

  const handleSubmitPicks = async () => {
    if (!userId || Object.keys(userPicks).length === 0) return;

    try {
      const db = getDatabase();
      const picksRef = ref(db, `MLSPicksTest/${userId}`);
      const totalPointsRef = ref(db, `MLSTotalPoints/${userId}`);
      
      // Process picks and update database
      for (const fixtureId in userPicks) {
        await set(ref(db, `MLSPicksTest/${userId}/${fixtureId}`), userPicks[fixtureId]);
      }
      
      // Update total points
      await set(totalPointsRef, totalPoints);

      setConfetti(true);
      setSent(true);
    } catch (error) {
      setError(true);
      console.error("Error submitting picks:", error);
    }
  };

  // Display loading message while fetching
  if (loading) {
    return <div>Loading fixtures...</div>;
  }

  return (
    <div>
      <Navbar />
      <div className='App-headerV3'>
        <h2 className='text-dark text-center'>MLS Picks</h2>
        <div className='dropdown'>
          <button className="btn btn-purple dropdown-toggle" type="button">
            {selectedMatchday}
          </button>
          <ul className="dropdown-menu">
            {dateRanges.map((range, index) => (
              <li key={index}>
                <button className="dropdown-item" onClick={() => handleMatchdaySelect(index)}>
                  Round {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className='container'>
          <div className='row'>
          

            <div className='col-lg-7 col-12'>
              {filteredFixtures.map((fixture) => (
                <div key={fixture.fixture.id} className='my-3 card'>
                  <div className='card-body text-center'>
                    <h5 className='card-title'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                    <div className='row'>
                      <div className="col">
                        <select value={userPicks[fixture.fixture.id]?.home || ''} onChange={(e) => handlePickChange(fixture.fixture.id, 'home', e.target.value)}>
                          <option value="">Pick</option>
                          {[...Array(11).keys()].map((num) => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <select value={userPicks[fixture.fixture.id]?.away || ''} onChange={(e) => handlePickChange(fixture.fixture.id, 'away', e.target.value)}>
                          <option value="">Pick</option>
                          {[...Array(11).keys()].map((num) => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className='text-center'>
                <button className='btn btn-purple' onClick={handleSubmitPicks}>Submit Picks</button>
                {confetti && <Confetti />}
              </div>
              {sent && <div className='alert alert-success'>Your picks have been submitted!</div>}
              {error && <div className='alert alert-danger'>Error submitting picks!</div>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TestApi;
