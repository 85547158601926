import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Arsenal from '../Team-Kits-Images/Arsenal.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Bournemouth from '../Team-Kits-Images/Bournemouth.png'
import Brentford from '../Team-Kits-Images/Brentford.png'
import Brighton from '../Team-Kits-Images/Brighton.png'
import Burnley from '../Team-Kits-Images/Burnley.png'
import Chelsea from '../Team-Kits-Images/Chelsea.png'
import CrystalPalace from '../Team-Kits-Images/CrystalPalace.png'
import Everton from '../Team-Kits-Images/Everton.png'
import Fulham from '../Team-Kits-Images/Fulham.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import Luton from '../Team-Kits-Images/LutonTown.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import ManUnited from '../Team-Kits-Images/Man.United.png'
import Newcastle from '../Team-Kits-Images/Newcastle.png'
import NottinghamForest from '../Team-Kits-Images/NottinghamForest.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Tottenham from '../Team-Kits-Images/Tottenham.png'
import WestHam from '../Team-Kits-Images/WestHam.png'
import Wolves from '../Team-Kits-Images/Wolves.png'
import Ipswich from '../Team-Kits-Images/Ipswitch.png'
import Leicester from '../Team-Kits-Images/Leicester.png'
import Southampton from '../Team-Kits-Images/Southampton.png'
import { Spinner } from 'react-bootstrap';


function WestHamStats() {
  const [teamStats, setTeamStats] = useState(null);
  const [loading, setLoading] = useState(true)
  const [nextFixture, setNextFixture] = useState(null)

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league-teams-stats/39/season/2024', {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        const Data = data.find(team => team.id === 48);
        if (Data) {
          setTeamStats(Data);
        } else {
          console.log(' data not found');
        }
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    const fetchNextFixture = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2024', {
            headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
              }
            })
        if (!response.ok) {
          throw new Error('Failed to fetch fixtures');
        }
        const fixturesData = await response.json();
        console.log('All fixtures:', fixturesData);

        const Fixtures = fixturesData.filter(fixture => {
          return fixture.teams.home.id === 48 || fixture.teams.away.id === 48;
        });
        console.log('Fixtures:', Fixtures);

        // Sort the fixtures by date in ascending order
        Fixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

        // Find the first upcoming fixture
        const upcomingFixture = Fixtures.find(fixture => {
          const fixtureDate = new Date(fixture.fixture.date); // Convert fixture date string to Date object
          const currentDate = new Date(); // Get current date
          return fixtureDate > currentDate; // Return true if fixture date is in the future
        });

        setNextFixture(upcomingFixture);
      } catch (error) {
        console.error('Error fetching fixtures:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
    fetchNextFixture();
  }, []);

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  const form = teamStats?.form || '';

  // Convert the form string into an array of characters
  const formArray = form.split('');

  // Get the last 5 matches from the form array
  const lastFiveMatches = formArray.slice(-5);

  const getClassForMatch = (result) => {
    switch (result) {
      case 'W':
        return 'success'; // Corrected class name to Bootstrap's 'success'
      case 'D':
        return 'warning'; // Corrected class name to Bootstrap's 'warning'
      case 'L':
        return 'danger'; // Corrected class name to Bootstrap's 'danger'
      default:
        return '';
    }
  };
  
  return (
    <div>
        <Navbar />
        <div className='App-headerV3'>
      {teamStats ? (
          <section className="bg-transparent py-3 py-md-5 py-xl-8">
          <div className="container">
              <div className="row justify-content-md-center">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                      <h2 className="mb-4 display-5 text-center text-dark">{teamStats.name}  Team Stats</h2>
                      <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                  </div>
              </div>
          </div>

          <div className="container">
              <div className="row gy-4 gy-lg-0">
                  <div className="col-12 col-lg-4 col-xl-3">
                      <div className="row gy-4">
                          <div className="col-12 astonvillakithome">
                              <div className="card widget-card border-light shadow-sm">
                                  <h6 className="card-header  text-center">{teamStats.name} Home Kit</h6>
                                  <div className="card-body">
                                      <div className="text-center mb-3">
                                          <img src={WestHam} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                      </div>
                               
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 astonvillakithome">
                              <div className="card widget-card border-light shadow-sm">
                                  <h6 className="card-header  text-center">Club Info</h6>
                                  <div className="card-body">
                                  <h5 className="text-center mb-1">Stadium</h5>
                                      <p className="text-center text-secondary mb-4">London Stadium</p>
                                      <h5 className="text-center mb-1">Manager</h5>
                                      <p className="text-center text-secondary mb-4">David Moyes</p>
                                      <h5 className="text-center mb-1">Year Founded</h5>
                                      <p className="text-center text-secondary mb-4">1895</p>
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 astonvillakithome">
                      <div className="card widget-card border-light shadow-sm">
                        <h6 className="card-header  text-center">{teamStats.name}'s Next EPL Fixture</h6>
                        <div className="card-body">
                        <h5 className="text-center mb-1">Last 5 EPL</h5>
    <p className="text-center text-secondary mb-1">
  {lastFiveMatches.map((result, index) => (
    <span key={index}>
      <span className={`badge bg-${getClassForMatch(result)} me-1`}>{result}</span>
    </span>
  ))}
</p>
                          {nextFixture ? (
                            <div>
                            <div className='d-flex text-center'>
                              <b className="text-center mb-1 col-5">
                                {/** start of kits */}
                                {' '}
                                                        {nextFixture.teams.home.name === 'Arsenal' ? (
                                                            <>
                                                              <div className='Arsenal' ><img src={Arsenal} alt="Arsenal" style={{ width: '75px', height: 'auto' }} /></div>  
                                                              <br />     <span>ARS</span>
                                                            </>
                                                        ) : nextFixture.teams.home.name === 'Aston Villa' ? (
                                                            <>
                                                                <img src={AstonVilla} alt="Aston Villa" style={{ width: '75px', height: 'auto' }} />
                                                                <br />   <span>AVL</span>

                                                            </>
                                                        ) : nextFixture.teams.home.name === 'Bournemouth' ? (
                                                            <>
                                                                <img src={Bournemouth} alt="Bournemouth" style={{ width: '75px', height: 'auto' }} />
                                                                <br />   <span>BOU</span>

                                                            </>
                                                        ) :
                                                            nextFixture.teams.home.name === 'Brentford' ? (
                                                                <>
                                                                    <img src={Brentford} alt="Brentford" style={{ width: '75px', height: 'auto' }} />
                                                                    <br />     <span>BRE</span>

                                                                </>
                                                            ) :
                                                                nextFixture.teams.home.name === 'Brighton' ? (
                                                                    <>
                                                                        <img src={Brighton} alt="Brighton" style={{ width: '75px', height: 'auto' }} />
                                                                        <br />     <span>BRI</span>

                                                                    </>
                                                                ) :
                                                                    nextFixture.teams.home.name === 'Burnley' ? (
                                                                        <>
                                                                            <img src={Burnley} alt="Burnley" style={{ width: '75px', height: 'auto' }} />
                                                                            <br />   <span>BUR</span>

                                                                        </>
                                                                    ) :
                                                                        nextFixture.teams.home.name === 'Chelsea' ? (
                                                                            <>
                                                                                <img src={Chelsea} alt="Chelsea" style={{ width: '75px', height: 'auto' }} />
                                                                                <br />    <span>CHE</span>

                                                                            </>
                                                                        ) :
                                                                            nextFixture.teams.home.name === 'Crystal Palace' ? (
                                                                                <>
                                                                                    <img src={CrystalPalace} alt="Crystal Palace" style={{ width: '75px', height: 'auto' }} />
                                                                                    <br />   <span>CRY</span>

                                                                                </>
                                                                            ) :
                                                                                nextFixture.teams.home.name === 'Everton' ? (
                                                                                    <>
                                                                                        <img src={Everton} alt="Everton" style={{ width: '75px', height: 'auto' }} />
                                                                                        <br /> <span>EVE</span>

                                                                                    </>
                                                                                ) :
                                                                                    nextFixture.teams.home.name === 'Fulham' ? (
                                                                                        <>
                                                                                            <img src={Fulham} alt="Fulham" style={{ width: '75px', height: 'auto' }} />
                                                                                            <br />  <span>FUL</span>

                                                                                        </>
                                                                                    ) :
                                                                                        nextFixture.teams.home.name === 'Liverpool' ? (
                                                                                            <>
                                                                                                <img src={Liverpool} alt="Liverpool" style={{ width: '75px', height: 'auto' }} />
                                                                                                <br />   <span>LIV</span>

                                                                                            </>
                                                                                        ) :
                                                                                            nextFixture.teams.home.name === 'Luton' ? (
                                                                                                <>
                                                                                                    <img src={Luton} alt="Luton" style={{ width: '75px', height: 'auto' }} />
                                                                                                    <br />     <span>LUT</span>

                                                                                                </>
                                                                                            ) :
                                                                                                nextFixture.teams.home.name === 'Manchester City' ? (
                                                                                                    <>

                                                                                                        <img src={ManCity} alt="Man City" style={{ width: '75px', height: 'auto' }} />
                                                                                                        <br />   <span>MCI</span>

                                                                                                    </>
                                                                                                ) :
                                                                                                    nextFixture.teams.home.name === 'Manchester United' ? (
                                                                                                        <>
                                                                                                            <img src={ManUnited} alt="Man United" style={{ width: '75px', height: 'auto' }} />
                                                                                                            <br />      <span>MUN</span>

                                                                                                        </>
                                                                                                    ) :
                                                                                                        nextFixture.teams.home.name === 'Newcastle' ? (
                                                                                                            <>
                                                                                                                <img src={Newcastle} alt="Newcastle" style={{ width: '75px', height: 'auto' }} />
                                                                                                                <br />      <span>NEW</span>

                                                                                                            </>
                                                                                                        ) :
                                                                                                            nextFixture.teams.home.name === 'Nottingham Forest' ? (
                                                                                                                <>
                                                                                                                    <img src={NottinghamForest} alt="Nottingham Forest" style={{ width: '75px', height: 'auto' }} />
                                                                                                                    <br />      <span>NFO</span>

                                                                                                                </>
                                                                                                            ) :
                                                                                                                nextFixture.teams.home.name === 'Sheffield Utd' ? (
                                                                                                                    <>
                                                                                                                        <img src={SheffieldUnited} alt="Sheffield Utd" style={{ width: '75px', height: 'auto' }} />
                                                                                                                        <br />   <span>SHU</span>

                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    nextFixture.teams.home.name === 'Tottenham' ? (
                                                                                                                        <>
                                                                                                                            <img src={Tottenham} alt="Tottenham" style={{ width: '75px', height: 'auto' }} />
                                                                                                                            <br />    <span>TOT</span>

                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        nextFixture.teams.home.name === 'West Ham' ? (
                                                                                                                            <>
                                                                                                                                <img src={WestHam} alt="West Ham" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                <br />    <span>WHU</span>

                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            nextFixture.teams.home.name === 'Wolves' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Wolves} alt="Wolves" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                  <br />  <span>WOL</span>

                                                                                                                                </>
                                                                                                                            ) :  nextFixture.teams.home.name === 'Ipswich' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Ipswich} alt="Ipswich" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                    <br />    <span>IPS</span>
        
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                                nextFixture.teams.home.name === 'Leicester' ? (
                                                                                                                                    <>
                                                                                                                                        <img src={Leicester} alt="Leicester" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                        <br />    <span>LEI</span>
        
                                                                                                                                    </>
                                                                                                                                ) :
                                                                                                                                    nextFixture.teams.home.name === 'Southampton' ? (
                                                                                                                                        <>
                                                                                                                                            <img src={Southampton} alt="Southampton" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                          <br />  <span>SOU</span>
        
                                                                                                                                        </>
                                                                                                                                    ) : (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {nextFixture.teams.home.name}
                                                                                                                                </span>
                                                                                                                            )}  
                                {/**end of kits */}
                                </b> 
                              <h5 className="text-center mb-1 col-2 padtopstats">VS</h5>
                              <b className="text-center mb-1 col-5">
                              {' '}
                                                        {nextFixture.teams.away.name === 'Arsenal' ? (
                                                            <>
                                                              <div className='Arsenal' ><img src={Arsenal} alt="Arsenal" style={{ width: '75px', height: 'auto' }} /></div>  
                                                              <br />     <span>ARS</span>
                                                            </>
                                                        ) : nextFixture.teams.away.name === 'Aston Villa' ? (
                                                            <>
                                                                <img src={AstonVilla} alt="Aston Villa" style={{ width: '75px', height: 'auto' }} />
                                                                <br />   <span>AVL</span>

                                                            </>
                                                        ) : nextFixture.teams.away.name === 'Bournemouth' ? (
                                                            <>
                                                                <img src={Bournemouth} alt="Bournemouth" style={{ width: '75px', height: 'auto' }} />
                                                                <br />   <span>BOU</span>

                                                            </>
                                                        ) :
                                                            nextFixture.teams.away.name === 'Brentford' ? (
                                                                <>
                                                                    <img src={Brentford} alt="Brentford" style={{ width: '75px', height: 'auto' }} />
                                                                    <br />     <span>BRE</span>

                                                                </>
                                                            ) :
                                                                nextFixture.teams.away.name === 'Brighton' ? (
                                                                    <>
                                                                        <img src={Brighton} alt="Brighton" style={{ width: '75px', height: 'auto' }} />
                                                                        <br />     <span>BRI</span>

                                                                    </>
                                                                ) :
                                                                    nextFixture.teams.away.name === 'Burnley' ? (
                                                                        <>
                                                                            <img src={Burnley} alt="Burnley" style={{ width: '75px', height: 'auto' }} />
                                                                            <br />   <span>BUR</span>

                                                                        </>
                                                                    ) :
                                                                        nextFixture.teams.away.name === 'Chelsea' ? (
                                                                            <>
                                                                                <img src={Chelsea} alt="Chelsea" style={{ width: '75px', height: 'auto' }} />
                                                                                <br />    <span>CHE</span>

                                                                            </>
                                                                        ) :
                                                                            nextFixture.teams.away.name === 'Crystal Palace' ? (
                                                                                <>
                                                                                    <img src={CrystalPalace} alt="Crystal Palace" style={{ width: '75px', height: 'auto' }} />
                                                                                    <br />   <span>CRY</span>

                                                                                </>
                                                                            ) :
                                                                                nextFixture.teams.away.name === 'Everton' ? (
                                                                                    <>
                                                                                        <img src={Everton} alt="Everton" style={{ width: '75px', height: 'auto' }} />
                                                                                        <br /> <span>EVE</span>

                                                                                    </>
                                                                                ) :
                                                                                    nextFixture.teams.away.name === 'Fulham' ? (
                                                                                        <>
                                                                                            <img src={Fulham} alt="Fulham" style={{ width: '75px', height: 'auto' }} />
                                                                                            <br />  <span>FUL</span>

                                                                                        </>
                                                                                    ) :
                                                                                        nextFixture.teams.away.name === 'Liverpool' ? (
                                                                                            <>
                                                                                                <img src={Liverpool} alt="Liverpool" style={{ width: '75px', height: 'auto' }} />
                                                                                                <br />   <span>LIV</span>

                                                                                            </>
                                                                                        ) :
                                                                                            nextFixture.teams.away.name === 'Luton' ? (
                                                                                                <>
                                                                                                    <img src={Luton} alt="Luton" style={{ width: '75px', height: 'auto' }} />
                                                                                                    <br />     <span>LUT</span>

                                                                                                </>
                                                                                            ) :
                                                                                                nextFixture.teams.away.name === 'Manchester City' ? (
                                                                                                    <>

                                                                                                        <img src={ManCity} alt="Man City" style={{ width: '75px', height: 'auto' }} />
                                                                                                        <br />   <span>MCI</span>

                                                                                                    </>
                                                                                                ) :
                                                                                                    nextFixture.teams.away.name === 'Manchester United' ? (
                                                                                                        <>
                                                                                                            <img src={ManUnited} alt="Man United" style={{ width: '75px', height: 'auto' }} />
                                                                                                            <br />      <span>MUN</span>

                                                                                                        </>
                                                                                                    ) :
                                                                                                        nextFixture.teams.away.name === 'Newcastle' ? (
                                                                                                            <>
                                                                                                                <img src={Newcastle} alt="Newcastle" style={{ width: '75px', height: 'auto' }} />
                                                                                                                <br />      <span>NEW</span>

                                                                                                            </>
                                                                                                        ) :
                                                                                                            nextFixture.teams.away.name === 'Nottingham Forest' ? (
                                                                                                                <>
                                                                                                                    <img src={NottinghamForest} alt="Nottingham Forest" style={{ width: '75px', height: 'auto' }} />
                                                                                                                    <br />      <span>NFO</span>

                                                                                                                </>
                                                                                                            ) :
                                                                                                                nextFixture.teams.away.name === 'Sheffield Utd' ? (
                                                                                                                    <>
                                                                                                                        <img src={SheffieldUnited} alt="Sheffield Utd" style={{ width: '75px', height: 'auto' }} />
                                                                                                                        <br />   <span>SHU</span>

                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    nextFixture.teams.away.name === 'Tottenham' ? (
                                                                                                                        <>
                                                                                                                            <img src={Tottenham} alt="Tottenham" style={{ width: '75px', height: 'auto' }} />
                                                                                                                            <br />    <span>TOT</span>

                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        nextFixture.teams.away.name === 'West Ham' ? (
                                                                                                                            <>
                                                                                                                                <img src={WestHam} alt="West Ham" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                <br />    <span>WHU</span>

                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            nextFixture.teams.away.name === 'Wolves' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Wolves} alt="Wolves" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                  <br />  <span>WOL</span>

                                                                                                                                </>
                                                                                                                            ) :  nextFixture.teams.away.name === 'Ipswich' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Ipswich} alt="Ipswich" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                    <br />    <span>IPS</span>
        
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                                nextFixture.teams.away.name === 'Leicester' ? (
                                                                                                                                    <>
                                                                                                                                        <img src={Leicester} alt="Leicester" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                        <br />    <span>LEI</span>
        
                                                                                                                                    </>
                                                                                                                                ) :
                                                                                                                                    nextFixture.teams.away.name === 'Southampton' ? (
                                                                                                                                        <>
                                                                                                                                            <img src={Southampton} alt="Southampton" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                          <br />  <span>SOU</span>
        
                                                                                                                                        </>
                                                                                                                                    ) : (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {nextFixture.teams.away.name}
                                                                                                                                </span>
                                                                                                                            )}  
                                </b>
                            </div>
                                                          <p className='text-center text-secondary mb-4'>{convertToFormattedDateTime(nextFixture.fixture.date)}</p>

                     
                                                          </div>

                          ) : (
                            <p className='text-center text-secondary'>No upcoming fixture</p>
                          )}
                        </div>
                      </div>
                    </div>
                      </div>
                  </div>
                  {/* gap */}
                  <div className="col-12 col-lg-8 col-xl-9">
                      <div className="card widget-card border-light shadow-sm">
                          <div className="card-body p-4 ">
                              <ul className="nav nav-tabs " id="profileTab" role="tablist">
                                  <li className="nav-item " role="presentation">
                                      <button className="nav-link active " id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">Overview</button>
                                  </li>
                                  <li className="nav-item text-danger" role="presentation">
                                      <button className="nav-link " id="profile-tab " data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Scoring Stats</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link " id="fun-tab" data-bs-toggle="tab" data-bs-target="#fun-tab-pane" type="button" role="tab" aria-controls="fun-tab-pane" aria-selected="false">Fun Stats</button>
                                  </li>
                              </ul>
                              <div className="tab-content pt-4" id="profileTabContent">
                                  <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex="0">
                                      {/* Content for Overview tab */}
                                      <h5 className="mb-3">EPL Games</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Games</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.played && teamStats.fixtures.played.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Won</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Drawn</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.draws && teamStats.fixtures.draws.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Lost</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.loses && teamStats.fixtures.loses.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Away Wins</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.away} </div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Home Wins</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.home} </div>
                                              </div> 
                                      </div>
                                      <h5 className="mb-3">Notable Results</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Home Win</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Away Win</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.away}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Home Loss</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Away Loss</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.away}</div>
                                              </div> 
                                      </div>
                                  </div>
                                  <div className="tab-pane fade" id="fun-tab-pane" role="tabpanel" aria-labelledby="fun-tab" tabIndex="2">
  {/* Content for Other Stats tab */}
  <h5 className="mb-3">Interesting Statistics</h5>

  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Yellow Cards</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
        {teamStats.cards && teamStats.cards.yellow && Object.values(teamStats.cards.yellow).reduce((acc, cur) => acc + cur.total, 0)}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Red Cards</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
        {teamStats.cards && teamStats.cards.red && Object.values(teamStats.cards.red).reduce((acc, cur) => acc + cur.total, 0)}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Pentalties Scored</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.total}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Pentalties Missed</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.missed && teamStats.penalty.missed.total}

      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Penalty Goal Rate</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.percentage}
      </div>
    </div> 
  </div>
</div>



                                  <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="1">
                                      {/* Content for Other Stats tab */}
                                      <h5 className="mb-3">Scoring Statistics</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored At Home</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored Away</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.away}</div>
                                              </div> 
                                      </div>
                 
                                      
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Average Goals Scored Per Game</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.average.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Average Goals Conceded Per Game</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.against && teamStats.goals.against.average.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Clean Sheets</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.clean_sheet && teamStats.clean_sheet.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Games Without Scoring</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.failed_to_score && teamStats.failed_to_score.total}</div>
                                              </div> 
                                      </div>
                                    
                                  </div>
                                  <hr></hr> <i>*note these statistics are only for the EPL</i>

                              </div>
                          </div>
                      </div>
                  </div>
                  {/*End of gap */}
              </div>
          </div>
      </section>
      ) : (
        <h2 className='text-center'><Spinner /></h2>
      )}
      </div>
      <Footer />
    </div>
  );
}
// {teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}
export default WestHamStats;
