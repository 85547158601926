import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ScotishStandingsOldAPI() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading ] = useState(true)
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();


  const fetchStandings = async () => {
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/179/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false)
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);


  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
      standingsMap[fixture.teams.away.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      // Determine match result
      let homePoints = 0;
      let awayPoints = 0;
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homePoints = 3;
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        awayPoints = 3;
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homePoints = 1;
        awayPoints = 1;
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      // Update standings
      standingsMap[homeTeam].points += homePoints;
      standingsMap[awayTeam].points += awayPoints;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
    });
  
    // Convert standings map to array
    const standingsArray = Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  
    return standingsArray;
  };
  
  return (
      <div className=''>
    <div className='App-headerV3'>
        <Navbar />
      <h2 className='text-center text-dark'>Scottish Premiership Standings</h2>
      <hr className="w-50 mx-auto mb-3 mb-xl-9 border-dark-subtle" />
      <div className='container text-center mb-2'><i className='text-center'>*Click on team name to view stats </i></div>
      <div className='d-flex justify-content-center'>
      <div className='col-6 col-md-3 text-center'>
        <select 
          className='form-select mb-3 text-center' 
          style={{ backgroundColor: 'purple', color: 'white' }}
          value={season} 
          onChange={(e) => setSeason(e.target.value)}
        >
          <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 24/25</option>
          <option value="2023" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 23/24</option>
          <option value="2022" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 22/23</option>
          <option value="2021" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 21/22</option>
          <option value="2020" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 20/21</option>
        </select>
      </div>
    </div>
      <div className='container'>
      {standings.length > 0 ? (

  <table className="table bg-transparent table-striped mb-2">
    <thead className='bg-transparent'>
        
      <tr>
        <th scope="col bg-transparent"style={{ backgroundColor: 'transparent',color: 'black' }}>#</th>
        <th scope="col bg-transparent"style={{ backgroundColor: 'transparent',color: 'black' }}>Team</th>
        <th scope="col bg-transparent"style={{ backgroundColor: 'transparent',color: 'black' }}>MP</th>
        <th scope="col"style={{ backgroundColor: 'transparent',color: 'black' }}>W</th>
        <th scope="col"style={{ backgroundColor: 'transparent',color: 'black' }}>D</th>
        <th scope="col"style={{ backgroundColor: 'transparent',color: 'black' }}>L</th>
        <th scope="col"style={{ backgroundColor: 'transparent',color: 'black' }}>Pts</th>
        {/* <th scope="col">Last 5</th> */}

      </tr>
    </thead>
    <tbody>
      {standings.map((team, index) => (
        <tr key={index}>
          <th scope="row" style={{ backgroundColor: 'transparent',color: 'black' }}>{index + 1}</th>
          <td style={{ backgroundColor: 'transparent', color: 'black' }}>
                      {team.team === 'ST Mirren' ? (
                        <Link to="/stmirren" style={{ textDecoration: 'none', color: 'black' }}>
                          ST Mirren
                        </Link>
                      ) : team.team === 'Celtic' ? (
                        <Link to="/Celtic" style={{ textDecoration: 'none', color: 'black' }}>Celtic</Link>
                      ) : team.team === 'Aberdeen' ? (
                        <Link to="/Aberdeen" style={{ textDecoration: 'none', color: 'black' }}>Aberdeen</Link>
                      ) : team.team === 'Heart Of Midlothian' ? (
                        <Link to="/hearts" style={{ textDecoration: 'none', color: 'black' }}>Hearts</Link>
                      ) : team.team === 'Rangers' ? (
                        <Link to="/Rangers" style={{ textDecoration: 'none', color: 'black' }}>Rangers</Link>
                      ) : team.team === 'Motherwell' ? (
                        <Link to="/motherwell" style={{ textDecoration: 'none', color: 'black' }}>Motherwell</Link>
                      ) : team.team === 'Ross County' ? (
                        <Link to="/RossCounty" style={{ textDecoration: 'none', color: 'black' }}>Ross County</Link>
                      ) : team.team === 'Dundee Utd' ? (
                        <Link to="/Dundeeutd" style={{ textDecoration: 'none', color: 'black' }}>Dundee Utd</Link>
                      ) : team.team === 'Dundee' ? (
                        <Link to="/Dundee" style={{ textDecoration: 'none', color: 'black' }}>Dundee	</Link>
                      ) : team.team === 'Hibernian' ? (
                        <Link to="/Hibernian" style={{ textDecoration: 'none', color: 'black' }}>Hibernian</Link>
                      ) : team.team === 'Kilmarnock' ? (
                        <Link to="/Kilmarnock" style={{ textDecoration: 'none', color: 'black' }}>Kilmarnock</Link>
                      ) : team.team === 'ST Johnstone' ? (
                        <Link to="/stjohnstone" style={{ textDecoration: 'none', color: 'black' }}>ST Johnstone</Link>
                      ) :  (
                        team.team
                      )}
                    </td>

          <td style={{ backgroundColor: 'transparent',color: 'black' }}>{team.matchesPlayed}</td>
          <td style={{ backgroundColor: 'transparent',color: 'black' }}>{team.wins}</td>
          <td style={{ backgroundColor: 'transparent',color: 'black' }}>{team.draws}</td>
          <td style={{ backgroundColor: 'transparent',color: 'black' }}>{team.losses}</td>
          <td style={{ backgroundColor: 'transparent',color: 'black' }}>  {team.points}</td>
        </tr>

      )
       )}
    </tbody>
  </table>
  ) : ( 
    <h2 className='text-center text-dark'><Spinner /></h2>
    )}


</div>
    </div>
    <Footer />
</div>
  );
}

export default ScotishStandingsOldAPI;

//   <td>{team.team === 'Manchester United' ? 'Manchester Utd' || 'Nottingham Forest' ? 'Nott Forest' :  team.team}</td>
