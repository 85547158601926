import React, { useState, useEffect } from 'react';
import { ref, getDatabase, onValue } from 'firebase/database';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import Invitations from '../Invitations';
import { BiFootball } from "react-icons/bi";

function Dashboard() {
    const [userUid, setUserUid] = useState('');
    const [joinedBrackets, setJoinedBrackets] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            setUserUid(user.uid);
        }

        const fetchJoinedBrackets = async () => {
            try {
                const database = getDatabase();
                const bracketsRef = ref(database, 'CreateBracket');
                onValue(bracketsRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const joinedBracketsArray = Object.entries(data).filter(([_, bracket]) =>
                            bracket.participants && bracket.participants[userUid]
                        );
                        setJoinedBrackets(joinedBracketsArray);
                    }
                });
            } catch (error) {
                console.error('Error fetching joined brackets:', error);
            }
        };

        fetchJoinedBrackets();
    }, [userUid]);

    const handleCategoryToggle = (category) => {
        setSelectedCategory(category);
    };

    const filteredBrackets = joinedBrackets.filter(([_, bracket]) => bracket.tournament === selectedCategory);

    return (
        <div className=''>
            <Navbar />
            <div className='App-headerV3'>
                <div className="container padtop">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                            <h2 className="mb-4 display-5 text-center" style={{ color: 'black' }}>Dashboard</h2>
                            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <i className='text-center mb-3'>*Select which huddle you would like to view</i>
                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                            <h3 className="mb-4 display-8 text-center" style={{ color: 'black' }}>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-purple"><BiFootball /></button>
                                    <button type="button" className="btn btn-outline-purple dropdown-toggle text-light" data-bs-toggle="dropdown" aria-expanded="false">
                                        Football
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('English Premier League')}>EPL 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('Championship')}>Championship 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('Scottish Premiership')}>SPFL 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('La Liga')}>La Liga 24/25</button></li>

                                    </ul>
                                </div>
                            </h3>
                        </div>
                    </div>
                    <section className="py-3 py-md-6">
                        <div className="container">
                            {selectedCategory && filteredBrackets.length > 0 ? (
                                filteredBrackets.map(([bracketId, bracket]) => (
                                    <Link
                                        to={`/bracket/${bracket.id}/${bracket.nameOfBracket}/participants`}
                                        className="list-group-item list-group-item-action"
                                        key={bracketId}
                                        style={{ marginBottom: '5px' }}
                                    >
                                        <div className="row justify-content-center" style={{ marginBottom: '10px' }}>
                                            <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                                                <div className="card widget-card border-light shadow-sm">
                                                    <div className="card-body p-4">
                                                        <h5 className="card-title widget-card-title mb-4">{bracket.nameOfBracket}</h5>
                                                        <div className="row gy-4">
                                                            <div className="col-12">
                                                                <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        <div className="d-flex align-items-center">
                                                                            <div>
                                                                                <div className="fs-5 bsb-w-50 bsb-h-50 bg-primary-subtle text-primary rounded-2 d-flex align-items-center justify-content-center me-3">
                                                                                    <img src={bracket.image} style={{ width: '40px', height: '40px', objectFit: 'cover' }} />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="m-0">{bracket.tournament}</h6>
                                                                                <p className="text-secondary m-0 fs-7">{bracket.purpose}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h6 className="text-end">{bracket.participantsCount} / {bracket.maxAmountOfPlayers}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            ) : selectedCategory ? (
                                <p className="text-center text-dark">You are not a part of any huddles for this tournament.</p>
                            ) : null}
                        </div>
                    </section>
                    <Invitations />
                </div>
            </div>
            <Footer className='bg-light' style={{color: 'white'}}/>
        </div>
    );
}

export default Dashboard;
