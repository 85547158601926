import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

import Celtic from '../Team-Kits-Images/Celtic.png'
import StMirren from '../Team-Kits-Images/StMirren.png'
import Aberdeen from '../Team-Kits-Images/Aberdeen.png'
import Hearts from '../Team-Kits-Images/Hearts.png'
import Rangers from '../Team-Kits-Images/Rangers.png'
import Motherwell from '../Team-Kits-Images/Motherwell.png'
import RossCounty from '../Team-Kits-Images/RossCounty.png'
import DundeeUtd from '../Team-Kits-Images/DundeeUtd.png'
import Dundee from '../Team-Kits-Images/Dundee.png'
import Hibernian from '../Team-Kits-Images/Hibernian.png'
import Kilmarnock from '../Team-Kits-Images/Kilmarnock.png'
import StJohnstone from '../Team-Kits-Images/StJohnstone.png'


import { Spinner } from 'react-bootstrap';


function StMirrenStats() {
  const [teamStats, setTeamStats] = useState(null);
  const [loading, setLoading] = useState(true)
  const [nextFixture, setNextFixture] = useState(null)

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league-teams-stats/179/season/2024', {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        const Data = data.find(team => team.id === 251);
        if (Data) {
          setTeamStats(Data);
        } else {
          console.log(' data not found');
        }
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    const fetchNextFixture = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league/179/fixtures/season/2024', {
            headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
              }
            })
        if (!response.ok) {
          throw new Error('Failed to fetch fixtures');
        }
        const fixturesData = await response.json();
        console.log('All fixtures:', fixturesData);

        const Fixtures = fixturesData.filter(fixture => {
          return fixture.teams.home.id === 251 || fixture.teams.away.id === 251;
        });
        console.log('Fixtures:', Fixtures);

        // Sort the fixtures by date in ascending order
        Fixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

        // Find the first upcoming fixture
        const upcomingFixture = Fixtures.find(fixture => {
          const fixtureDate = new Date(fixture.fixture.date); // Convert fixture date string to Date object
          const currentDate = new Date(); // Get current date
          return fixtureDate > currentDate; // Return true if fixture date is in the future
        });

        setNextFixture(upcomingFixture);
      } catch (error) {
        console.error('Error fetching fixtures:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
    fetchNextFixture();
  }, []);

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  const form = teamStats?.form || '';

  // Convert the form string into an array of characters
  const formArray = form.split('');

  // Get the last 5 matches from the form array
  const lastFiveMatches = formArray.slice(-5);

  const getClassForMatch = (result) => {
    switch (result) {
      case 'W':
        return 'success'; // Corrected class name to Bootstrap's 'success'
      case 'D':
        return 'warning'; // Corrected class name to Bootstrap's 'warning'
      case 'L':
        return 'danger'; // Corrected class name to Bootstrap's 'danger'
      default:
        return '';
    }
  };

  
  return (
    <div>
        <Navbar />
        <div className='App-headerV3'>
      {teamStats ? (
          <section className="bg-transparent py-3 py-md-5 py-xl-8">
          <div className="container">
              <div className="row justify-content-md-center">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                      <h2 className="mb-4 display-5 text-center text-dark">{teamStats.name}  Team Stats</h2>
                      <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                  </div>
              </div>
          </div>

          <div className="container">
              <div className="row gy-4 gy-lg-0">
                  <div className="col-12 col-lg-4 col-xl-3">
                      <div className="row gy-4">
                          <div className="col-12 ">
                              <div className="card widget-card border-light shadow-sm">
                                  <h6 className="card-header text-bg-dark text-center">{teamStats.name} Home Kit</h6>
                                  <div className="card-body">
                                      <div className="text-center mb-3">
                                          <img src={StMirren} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                      </div>
                               
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 ">
                              <div className="card widget-card border-light shadow-sm">
                                  <h6 className="card-header text-bg-dark text-center">Club Info</h6>
                                  <div className="card-body">
                                  <h5 className="text-center mb-1">Stadium</h5>
                                      <p className="text-center text-secondary mb-4">St Mirren Park</p>
                                      <h5 className="text-center mb-1">Manager</h5>
                                      <p className="text-center text-secondary mb-4">Stephen Robinson                                      </p>
                                      <h5 className="text-center mb-1">Year Founded</h5>
                                      <p className="text-center text-secondary mb-4">1877</p>
                                  </div>
                              </div>
                          </div>
                          <div className="col-12">
                      <div className="card widget-card border-light shadow-sm">
                        <h6 className="card-header text-bg-dark text-center">{teamStats.name}'s Next SPFL Fixture</h6>
                        <div className="card-body">
                        <h5 className="text-center mb-1">Last 5 SPFL</h5>
    <p className="text-center text-secondary mb-1">
  {lastFiveMatches.map((result, index) => (
    <span key={index}>
      <span className={`badge bg-${getClassForMatch(result)} me-1`}>{result}</span>
    </span>
  ))}
</p>
                          {nextFixture ? (
                            <div>
                            <div className='d-flex text-center'>
                              <b className="text-center mb-1 col-5">
                                {/** start of kits */}
                                {' '}
                                                        {nextFixture.teams.home.name === 'Rangers' ? (
                                                            <>
                                                              <div className='Arsenal' ><img src={Rangers} alt="Arsenal" style={{ width: '75px', height: 'auto' }} /></div>  
                                                              <br />     <span>RAN</span>
                                                            </>
                                                        ) : nextFixture.teams.home.name === 'ST Mirren' ? (
                                                            <>
                                                                <img src={StMirren} alt="ST Mirren" style={{ width: '75px', height: 'auto' }} />
                                                                <br />   <span>STM</span>

                                                            </>
                                                        ) : nextFixture.teams.home.name === 'Celtic' ? (
                                                            <>
                                                                <img src={Celtic} alt="Celtic" style={{ width: '75px', height: 'auto' }} />
                                                                <br />   <span>CEL</span>

                                                            </>
                                                        ) :
                                                            nextFixture.teams.home.name === 'Aberdeen' ? (
                                                                <>
                                                                    <img src={Aberdeen} alt="Aberdeen" style={{ width: '75px', height: 'auto' }} />
                                                                    <br />     <span>ABE</span>

                                                                </>
                                                            ) :
                                                                nextFixture.teams.home.name === 'Heart Of Midlothian' ? (
                                                                    <>
                                                                        <img src={Hearts} alt="Heart Of Midlothian" style={{ width: '75px', height: 'auto' }} />
                                                                        <br />     <span>HOM</span>

                                                                    </>
                                                                ) :
                                                                    nextFixture.teams.home.name === 'Motherwell' ? (
                                                                        <>
                                                                            <img src={Motherwell} alt="Motherwell" style={{ width: '75px', height: 'auto' }} />
                                                                            <br />   <span>MOT</span>

                                                                        </>
                                                                    ) :
                                                                        nextFixture.teams.home.name === 'Ross County' ? (
                                                                            <>
                                                                                <img src={RossCounty} alt="Ross County" style={{ width: '75px', height: 'auto' }} />
                                                                                <br />    <span>ROS</span>

                                                                            </>
                                                                        ) :
                                                                            nextFixture.teams.home.name === 'Dundee Utd' ? (
                                                                                <>
                                                                                    <img src={DundeeUtd} alt="Dundee Utd" style={{ width: '75px', height: 'auto' }} />
                                                                                    <br />   <span>DUU</span>

                                                                                </>
                                                                            ) :
                                                                                nextFixture.teams.home.name === 'Dundee' ? (
                                                                                    <>
                                                                                        <img src={Dundee} alt="Dundee" style={{ width: '75px', height: 'auto' }} />
                                                                                        <br /> <span>DUN</span>

                                                                                    </>
                                                                                ) :
                                                                                    nextFixture.teams.home.name === 'Hibernian' ? (
                                                                                        <>
                                                                                            <img src={Hibernian} alt="Hibernian" style={{ width: '75px', height: 'auto' }} />
                                                                                            <br />  <span>HIB</span>

                                                                                        </>
                                                                                    ) :
                                                                                        nextFixture.teams.home.name === 'Kilmarnock' ? (
                                                                                            <>
                                                                                                <img src={Kilmarnock} alt="Kilmarnock" style={{ width: '75px', height: 'auto' }} />
                                                                                                <br />   <span>KIL</span>

                                                                                            </>
                                                                                        ) :
                                                                                            nextFixture.teams.home.name === 'ST Johnstone' ? (
                                                                                                <>
                                                                                                    <img src={StJohnstone} alt="ST Johnstone" style={{ width: '75px', height: 'auto' }} />
                                                                                                    <br />     <span>LUT</span>

                                                                                                </>
                                                                                            ) :
                                                                                                (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {nextFixture.teams.home.name}
                                                                                                                                </span>
                                                                                                                            )}  
                                {/**end of kits */}
                                </b> 
                              <h5 className="text-center mb-1 col-2 padtopstats">VS</h5>
                              <b className="text-center mb-1 col-5">
                                  {/** start of kits */}
                                  {' '}
                                                        {nextFixture.teams.away.name === 'Rangers' ? (
                                                            <>
                                                              <div className='Arsenal' ><img src={Rangers} alt="Arsenal" style={{ width: '75px', height: 'auto' }} /></div>  
                                                              <br />     <span>RAN</span>
                                                            </>
                                                        ) : nextFixture.teams.away.name === 'ST Mirren' ? (
                                                            <>
                                                                <img src={StMirren} alt="ST Mirren" style={{ width: '75px', height: 'auto' }} />
                                                                <br />   <span>STM</span>

                                                            </>
                                                        ) : nextFixture.teams.away.name === 'Celtic' ? (
                                                            <>
                                                                <img src={Celtic} alt="Celtic" style={{ width: '75px', height: 'auto' }} />
                                                                <br />   <span>CEL</span>

                                                            </>
                                                        ) :
                                                            nextFixture.teams.away.name === 'Aberdeen' ? (
                                                                <>
                                                                    <img src={Aberdeen} alt="Aberdeen" style={{ width: '75px', height: 'auto' }} />
                                                                    <br />     <span>ABE</span>

                                                                </>
                                                            ) :
                                                                nextFixture.teams.away.name === 'Heart Of Midlothian' ? (
                                                                    <>
                                                                        <img src={Hearts} alt="Heart Of Midlothian" style={{ width: '75px', height: 'auto' }} />
                                                                        <br />     <span>HOM</span>

                                                                    </>
                                                                ) :
                                                                    nextFixture.teams.away.name === 'Motherwell' ? (
                                                                        <>
                                                                            <img src={Motherwell} alt="Motherwell" style={{ width: '75px', height: 'auto' }} />
                                                                            <br />   <span>MOT</span>

                                                                        </>
                                                                    ) :
                                                                        nextFixture.teams.away.name === 'Ross County' ? (
                                                                            <>
                                                                                <img src={RossCounty} alt="Ross County" style={{ width: '75px', height: 'auto' }} />
                                                                                <br />    <span>ROS</span>

                                                                            </>
                                                                        ) :
                                                                            nextFixture.teams.away.name === 'Dundee Utd' ? (
                                                                                <>
                                                                                    <img src={DundeeUtd} alt="Dundee Utd" style={{ width: '75px', height: 'auto' }} />
                                                                                    <br />   <span>DUU</span>

                                                                                </>
                                                                            ) :
                                                                                nextFixture.teams.away.name === 'Dundee' ? (
                                                                                    <>
                                                                                        <img src={Dundee} alt="Dundee" style={{ width: '75px', height: 'auto' }} />
                                                                                        <br /> <span>DUN</span>

                                                                                    </>
                                                                                ) :
                                                                                    nextFixture.teams.away.name === 'Hibernian' ? (
                                                                                        <>
                                                                                            <img src={Hibernian} alt="Hibernian" style={{ width: '75px', height: 'auto' }} />
                                                                                            <br />  <span>HIB</span>

                                                                                        </>
                                                                                    ) :
                                                                                        nextFixture.teams.away.name === 'Kilmarnock' ? (
                                                                                            <>
                                                                                                <img src={Kilmarnock} alt="Kilmarnock" style={{ width: '75px', height: 'auto' }} />
                                                                                                <br />   <span>KIL</span>

                                                                                            </>
                                                                                        ) :
                                                                                            nextFixture.teams.away.name === 'ST Johnstone' ? (
                                                                                                <>
                                                                                                    <img src={StJohnstone} alt="ST Johnstone" style={{ width: '75px', height: 'auto' }} />
                                                                                                    <br />     <span>LUT</span>

                                                                                                </>
                                                                                            ) :
                                                                                                (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {nextFixture.teams.away.name}
                                                                                                                                </span>
                                                                                                                            )}  
                                {/**end of kits */}
                                </b>
                            </div>
                                                          <p className='text-center text-secondary mb-4'>{convertToFormattedDateTime(nextFixture.fixture.date)}</p>

                     
                                                          </div>

                          ) : (
                            <p className='text-center text-secondary'>No upcoming fixture</p>
                          )}
                        </div>
                      </div>
                    </div>
                      </div>
                  </div>
                  {/* gap */}
                  <div className="col-12 col-lg-8 col-xl-9">
                      <div className="card widget-card border-light shadow-sm">
                          <div className="card-body p-4 ">
                              <ul className="nav nav-tabs " id="profileTab" role="tablist">
                                  <li className="nav-item " role="presentation">
                                      <button className="nav-link active " id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">Overview</button>
                                  </li>
                                  <li className="nav-item text-danger" role="presentation">
                                      <button className="nav-link " id="profile-tab " data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Scoring Stats</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link " id="fun-tab" data-bs-toggle="tab" data-bs-target="#fun-tab-pane" type="button" role="tab" aria-controls="fun-tab-pane" aria-selected="false">Fun Stats</button>
                                  </li>
                              </ul>
                              <div className="tab-content pt-4" id="profileTabContent">
                                  <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex="0">
                                      {/* Content for Overview tab */}
                                      <h5 className="mb-3">SPFL Games</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Games</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.played && teamStats.fixtures.played.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Won</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Drawn</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.draws && teamStats.fixtures.draws.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Lost</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.loses && teamStats.fixtures.loses.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Away Wins</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.away} </div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Home Wins</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.home} </div>
                                              </div> 
                                      </div>
                                      <h5 className="mb-3">Notable Results</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Home Win</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Away Win</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.away}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Home Loss</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Away Loss</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.away}</div>
                                              </div> 
                                      </div>
                                  </div>
                                  <div className="tab-pane fade" id="fun-tab-pane" role="tabpanel" aria-labelledby="fun-tab" tabIndex="2">
  {/* Content for Other Stats tab */}
  <h5 className="mb-3">Interesting Statistics</h5>

  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Yellow Cards</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
        {teamStats.cards && teamStats.cards.yellow && Object.values(teamStats.cards.yellow).reduce((acc, cur) => acc + cur.total, 0)}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Red Cards</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
        {teamStats.cards && teamStats.cards.red && Object.values(teamStats.cards.red).reduce((acc, cur) => acc + cur.total, 0)}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Pentalties Scored</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.total}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Pentalties Missed</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.missed && teamStats.penalty.missed.total}

      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Penalty Goal Rate</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.percentage}
      </div>
    </div> 
  </div>
</div>



                                  <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="1">
                                      {/* Content for Other Stats tab */}
                                      <h5 className="mb-3">Scoring Statistics</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored At Home</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored Away</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.away}</div>
                                              </div> 
                                      </div>
                 
                                      
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Average Goals Scored Per Game</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.average.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Average Goals Conceded Per Game</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.against && teamStats.goals.against.average.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Clean Sheets</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.clean_sheet && teamStats.clean_sheet.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Games Without Scoring</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.failed_to_score && teamStats.failed_to_score.total}</div>
                                              </div> 
                                      </div>
                                    
                                  </div>
                                  <hr></hr> <i>*note these statistics are only for the SPFL</i>

                              </div>
                          </div>
                      </div>
                  </div>
                  {/*End of gap */}
              </div>
          </div>
      </section>
      ) : (
        <h2 className='text-center'><Spinner /></h2>
      )}
      </div>
      <Footer />
    </div>
  );
}
// {teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}
export default StMirrenStats;
